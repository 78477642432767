import React from "react";

import {
  Box,
  Paper,
  Grid,
  Typography,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { mergeDarkTheme } from "@aclymatepackages/themes";

const DetailsActionAlert = ({ color, icon, text, action }) => {
  const { palette } = useTheme();

  return (
    <Grid item>
      <Paper style={{ backgroundColor: color || palette.error.main }}>
        <ThemeProvider theme={mergeDarkTheme}>
          <Box p={1}>
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <FontAwesomeIcon
                  icon={icon}
                  style={{ color: "white" }}
                  size="2x"
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {text}
                </Typography>
              </Grid>
              {action && <Grid item>{action}</Grid>}
            </Grid>
          </Box>
        </ThemeProvider>
      </Paper>
    </Grid>
  );
};
export default DetailsActionAlert;
