import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  ThemeProvider,
} from "@mui/material";

import { DatePicker } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { darkTheme } from "@aclymatepackages/themes";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { calcOfficeUtilitiesPerSqFootage } from "@aclymatepackages/calcs/recurring/defaultCalcs";

import StatePercentagesInterface from "./StatePercentagesInterface";
import PlacesAutocomplete from "../../inputs/autocomplete/PlacesAutocomplete";
import PdlCompanySelect from "../../inputs/PdlCompanySelect";
import MultiPartForm from "../../inputs/flows/MultipartForm";
import useEventAttendeesUploader from "../../hooks/eventAttendeesCsvUploader";

import { isAttendeeStateBreakdownPercentageError } from "../../../helpers/components/events";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import {
  useCachedDisplayData,
  useCachedFirebaseCrud,
  useAccountData,
} from "../../../helpers/firebase";
import { setAddressUpdateAirport } from "../../../helpers/utils/geography";
import { fetchZipCodeEGrid } from "../../../helpers/utils/apiCalls";

const AdminCompanySelection = ({
  setCompanyName,
  setCompanyId,
  ...otherProps
}) => {
  const createNewCompanyId = async (companyData) =>
    await fetchOurApi({
      path: "/companies/new-event-admin-company",
      method: "POST",
      data: { ...companyData, origin: "event-admin" },
      callback: ({ companyId }) => companyId,
    });

  const onCompanyNotFound = async (name) => {
    const companyId = await createNewCompanyId({ name });
    setCompanyName(name);
    return setCompanyId(companyId);
  };

  const onCompanySelected = async ({ dbId, pdlId, name }) => {
    if (dbId) {
      setCompanyId(dbId);
      return setCompanyName(name);
    }

    const companyId = await createNewCompanyId({
      name,
      peopleDataLabsCompanyId: pdlId,
    });
    setCompanyName(name);
    return setCompanyId(companyId);
  };

  return (
    <PdlCompanySelect
      label="Which company are you creating this event for?"
      onCompanyNotFound={onCompanyNotFound}
      onCompanySelected={onCompanySelected}
      {...otherProps}
    />
  );
};

const EmployeesAutoComplete = ({ options, value, setValue, multiple }) => {
  return (
    <Autocomplete
      id="employees-autocomplete"
      multiple={multiple}
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(_, newValue) => {
        const newValues = newValue.map((value) => ({
          id: value.id,
          name: value.name,
        }));
        setValue(newValues);
      }}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Please select employees that are not attending"
          placeholder="Please select employees that are not attending"
        />
      )}
    />
  );
};

const SelectAttendeesStatesDistribution = ({
  attendeesStateBreakdown,
  setAttendeesStateBreakdown,
}) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container direction="column" alignItems="center" rowGap={2}>
        <Grid item>
          <Typography variant="h6">
            Please select the states that the attendees are coming from.
          </Typography>
        </Grid>
        <Grid item width="100%">
          <StatePercentagesInterface
            stateBreakdown={attendeesStateBreakdown}
            setStateBreakdown={setAttendeesStateBreakdown}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const NewEventForm = ({ setShowNewEventForm }) => {
  const { newCollectionDoc } = useCachedFirebaseCrud();

  const [{ startDate: companyStartDate, name: accountName, id: accountId }] =
    useAccountData();

  const [newEvent, setNewEvent] = useState({});
  const [employees] = useCachedDisplayData("employees");
  const [attendeesStateBreakdown, setAttendeesStateBreakdown] = useState([{}]);
  const [
    employeeListForNotAttendingEvent,
    setEmployeeListForNotAttendingEvent,
  ] = useState([]);

  const { companyId, companyName, ...mainEventProps } = newEvent;

  const {
    isOwnEvent,
    name,
    address,
    sqFootage,
    attendeeCount,
    startDate,
    endDate,
    isAllEmployeesAttending,
    isCsvUploaded,
    areOtherAttendees,
  } = mainEventProps;

  const editNewEvent = (field) => (value) =>
    editObjectData(setNewEvent, field, value);

  const areAllFieldsEmptyForStates =
    areOtherAttendees &&
    attendeesStateBreakdown.every(
      ({ state, percentage }) => !state && !percentage
    );
  const areAllFieldsProvidedForStates = attendeesStateBreakdown.every(
    ({ state, percentage }) =>
      state && percentage && numbersRegExpTest(percentage)
  );
  const isAttendeeStateBreakdownValid =
    areAllFieldsProvidedForStates &&
    !isAttendeeStateBreakdownPercentageError(attendeesStateBreakdown);

  const getEmployeeIdsWhoAreAttending = ({
    employees,
    employeeListForNotAttendingEvent,
  }) => {
    if (isAllEmployeesAttending) {
      return employees.map(({ id }) => id);
    }

    return employees
      .filter(
        ({ id }) =>
          !employeeListForNotAttendingEvent.find(
            ({ id: notAttendingId }) => notAttendingId === id
          )
      )
      .map(({ id }) => id);
  };

  const createNewEvent = async () => {
    const eGrid = await fetchZipCodeEGrid(address);

    const { monthlyElectricCarbonTons, monthlyGasCarbonTons } =
      calcOfficeUtilitiesPerSqFootage({
        ...address,
        sqFootage,
        eGrid,
        date: startDate,
        type: "lodging",
      });

    const numberOfDays = Math.abs(
      dayjs(startDate).diff(dayjs(endDate), "days")
    );

    const monthFraction = numberOfDays / 30;

    const employeeIdsWhoAreAttending = getEmployeeIdsWhoAreAttending({
      employees,
      employeeListForNotAttendingEvent,
    });

    const attendeesStateBreakdownObj = isAttendeeStateBreakdownValid
      ? {
          attendeesStateBreakdown,
        }
      : {};

    const companyIds = [accountId, companyId].filter((id) => id);
    const companies = [
      { name: companyName, id: companyId, type: "owner" },
      { name: accountName, id: accountId, type: "admin " },
    ].filter(({ name, id }) => name && id);

    const eventDocId = await newCollectionDoc("events", {
      companyIds,
      companies,
      ...mainEventProps,
      employeeIds: employeeIdsWhoAreAttending,
      venueGasTons: monthlyGasCarbonTons * monthFraction,
      venueElectricTons: monthlyElectricCarbonTons * monthFraction,
      ...attendeesStateBreakdownObj,
    });

    if (employeeIdsWhoAreAttending && employeeIdsWhoAreAttending.length) {
      await newCollectionDoc("tasks", {
        status: "uploading",
        type: "event-attendees-csv",
        eventId: eventDocId,
        employeeIds: employeeIdsWhoAreAttending,
      });
    }

    return eventDocId;
  };

  const eventAttendeesCsvUploaderSteps = useEventAttendeesUploader({
    setFormOpen: setShowNewEventForm,
    createNewEvent,
  });

  const adminEventCreationRow =
    isOwnEvent === false
      ? [
          {
            input: companyId ? (
              <Typography variant="h5" align="center">
                You're creating an event for {companyName}
              </Typography>
            ) : (
              <AdminCompanySelection
                setCompanyName={editNewEvent("companyName")}
                setCompanyId={editNewEvent("companyId")}
              />
            ),
            value: companyId,
          },
        ]
      : [];

  const newEventInputRows = [
    {
      value: isOwnEvent || isOwnEvent === false,
      input: (
        <YesNoQuestion
          question="Are you creating this event for your company?"
          value={isOwnEvent}
          setValue={editNewEvent("isOwnEvent")}
        />
      ),
    },
    ...adminEventCreationRow,
    {
      value: name,
      editData: editNewEvent("name"),
      label: "What is your event's name",
    },
    {
      value: address,
      input: (
        <PlacesAutocomplete
          label="What is the address of your event's venue?"
          place={address || null}
          editPlace={setAddressUpdateAirport(editNewEvent)}
          size="small"
        />
      ),
    },
    {
      value: sqFootage,
      editData: editNewEvent("sqFootage"),
      label: "What's the square footage of your event's venue?",
      error: sqFootage && !numbersRegExpTest(sqFootage),
    },
    {
      value: startDate,
      input: (
        <DatePicker
          label="When does your event start?"
          date={startDate}
          minDate={companyStartDate}
          editDate={editNewEvent("startDate")}
          darkTheme
        />
      ),
    },
    {
      value: endDate,
      input: (
        <DatePicker
          label="When does your event end?"
          date={endDate}
          editDate={editNewEvent("endDate")}
          darkTheme
          minDate={startDate}
        />
      ),
    },
  ];

  const employeeListInput = !isAllEmployeesAttending
    ? [
        {
          value: !!employeeListForNotAttendingEvent.length,
          input: (
            <EmployeesAutoComplete
              multiple
              options={employees}
              value={employeeListForNotAttendingEvent}
              setValue={setEmployeeListForNotAttendingEvent}
            />
          ),
        },
      ]
    : [];

  const employeeInputRows = [
    {
      value: isAllEmployeesAttending || isAllEmployeesAttending === false,
      input: (
        <YesNoQuestion
          question="Are all of your employees attending the event?"
          value={isAllEmployeesAttending}
          setValue={editNewEvent("isAllEmployeesAttending")}
        />
      ),
    },
    ...employeeListInput,
  ];

  const manualAttendeesInputRows = isCsvUploaded
    ? []
    : [
        {
          value: attendeeCount,
          editData: editNewEvent("attendeeCount"),
          label: "How many people are attending the event?",
          error: attendeeCount && !numbersRegExpTest(attendeeCount),
        },
        {
          value: areAllFieldsEmptyForStates
            ? true
            : isAttendeeStateBreakdownValid,

          input: (
            <SelectAttendeesStatesDistribution
              attendeesStateBreakdown={attendeesStateBreakdown}
              setAttendeesStateBreakdown={setAttendeesStateBreakdown}
            />
          ),
        },
      ];

  const areOtherAttendeesQuestion = {
    value: areOtherAttendees || areOtherAttendees === false,
    input: (
      <YesNoQuestion
        question="Is anyone other than your employees attending this event?"
        value={areOtherAttendees}
        setValue={editNewEvent("areOtherAttendees")}
      />
    ),
  };

  const otherAttendeesQuestionsRows = areOtherAttendees
    ? [
        {
          value: isCsvUploaded || isCsvUploaded === false,
          input: (
            <YesNoQuestion
              question="Do you want to upload a csv file of attendees?"
              value={isCsvUploaded}
              setValue={editNewEvent("isCsvUploaded")}
            />
          ),
        },
        ...manualAttendeesInputRows,
      ]
    : [];

  const attendeesInputRows = [
    areOtherAttendeesQuestion,
    ...otherAttendeesQuestionsRows,
  ];

  const eventAttendeesOnAdvanceObj = !isCsvUploaded
    ? {
        onAdvanceForm: async () => {
          await createNewEvent();

          return setShowNewEventForm(false);
        },
      }
    : {};

  const eventAttendeesCsvUploaderSectionObj = isCsvUploaded
    ? eventAttendeesCsvUploaderSteps
    : [];

  const newEventFormSections = [
    {
      name: "basicEventDetails",
      label: "Basic Details",
      title: "Answer some basic questions about your event.",
      rows: newEventInputRows,
    },
    {
      name: "employeeDetails",
      label: "Employee Details",
      title: "Let's get some details about the employees attending the event.",
      rows: employeeInputRows,
    },
    {
      name: "eventAttendees",
      label: "Event Attendees",
      title: "Upload the list of attendees for your event or enter manually.",
      rows: attendeesInputRows,
      submitButtonText:
        isCsvUploaded && areAllFieldsEmptyForStates ? "Skip" : "Next Step",
      ...eventAttendeesOnAdvanceObj,
    },
    ...eventAttendeesCsvUploaderSectionObj,
  ];

  return (
    <MultiPartForm
      onClose={() => setShowNewEventForm(false)}
      type="events"
      forms={newEventFormSections}
    />
  );
};
export default NewEventForm;
