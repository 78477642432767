import React, { useState, useContext } from "react";

import {
  Container,
  Grid,
  Button,
  TableRow,
  TableCell,
  Chip,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  Rating,
  useTheme,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import mainTheme from "@aclymatepackages/themes";
import {
  Checkbox,
  CategoriesAvatar,
  TextField,
  LoadingButton,
  DefaultPaper,
} from "@aclymatepackages/atoms";
import {
  formatDecimal,
  letterSBoolean,
  formatDate,
} from "@aclymatepackages/formatters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import findSubcategory from "@aclymatepackages/subcategories";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";

import VendorsGraphs from "./VendorsGraph";
import EmissionsDetails from "../../EmissionsDetails";
import SettingsObjectDetailsSlider from "../../SettingsObjectDetailsSlider";
import EditRowTransactions from "../../EditRowTransactions";
import DetailsActionAlert from "../../../../atoms/notifications/DetailsActionAlert";

import ErrorBoundary from "../../../../atoms/ErrorBoundary";
import SortableTable, {
  makeColumnObj,
} from "../../../../modules/tables/SortableTable";
import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import { MissingVendors } from "../../../../layouts/missingSettingsLayouts";
import VendorInputForm from "../../../../inputs/vendors/VendorInputForm";
import EmissionsCategorySelect from "../../../../inputs/vendors/EmissionsCategorySelect";
import ScopeThreeCategorySelect from "../../../../inputs/vendors/ScopeThreeCategorySelect";
import NaicsCodesAutocomplete from "../../../../inputs/autocomplete/NaicsCodesAutocomplete";
import useStatusFilterChips from "../../../../hooks/statusFilterChips";

import { truncateText } from "../../../../../helpers/otherHelpers";
import {
  useVendorData,
  vendorStatuses,
  useOnVendorSave,
  useUpdateVendorTransactions,
} from "../../../../../helpers/components/vendors";
import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import { useCachedFirebaseCrud } from "../../../../../helpers/firebase";
import { fetchOurApi } from "../../../../../helpers/utils/apiCalls";

const VendorCategoryChip = ({ emissionCategory, naicsTitle }) => {
  const { name: categoryName, color } = findSubcategory(emissionCategory) || {};

  return (
    <Chip
      style={{
        backgroundColor: emissionCategory
          ? color
          : mainTheme.palette.error.main,
        color: "white",
      }}
      avatar={
        emissionCategory && (
          <CategoriesAvatar subcategory={emissionCategory} size="small" />
        )
      }
      icon={!emissionCategory && <ErrorIcon style={{ color: "white" }} />}
      label={
        !emissionCategory
          ? "Uncategorized Vendor"
          : emissionCategory === "spend-based"
          ? truncateText(naicsTitle, 20)
          : categoryName
      }
    />
  );
};

const VendorsTableRow = ({
  vendor,
  setSelectedVendor,
  checkedVendors,
  setCheckedVendors,
}) => {
  const {
    id,
    status,
    name: vendorName,
    tonsCo2e,
    avgTonsCo2ePerTransaction,
    transactionsCount,
    vendorScore,
    ...otherProps
  } = vendor;
  const {
    icon: statusIcon,
    color: statusColor,
    tooltip: statusTooltip,
  } = vendorStatuses[status] || {};

  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const onCheckVendor = (isChecked) => {
    if (isChecked) {
      return setCheckedVendors((currentlyCheckedVendors) => [
        ...currentlyCheckedVendors,
        vendor,
      ]);
    }

    return setCheckedVendors((currentlyCheckedVendors) =>
      currentlyCheckedVendors.filter((vendor) => vendor.id !== id)
    );
  };

  const tableCells = [
    <Checkbox
      value={!!checkedVendors.find((vendor) => vendor.id === id)}
      editValue={onCheckVendor}
    />,
    <Tooltip title={statusTooltip}>
      <span>
        <FontAwesomeIcon
          icon={statusIcon}
          size="2x"
          style={{ color: statusColor }}
        />
      </span>
    </Tooltip>,
    vendorName,
    <VendorCategoryChip {...otherProps} />,
    formatDecimal(convertCarbonUnits(tonsCo2e)),
    formatDecimal(convertCarbonUnits(avgTonsCo2ePerTransaction)),
    formatDecimal(transactionsCount),
    <Rating value={vendorScore / 30} readOnly max={3} />,
  ];
  return (
    <TableRow hover onClick={() => setSelectedVendor(vendor)}>
      {tableCells.map((cell, idx) => (
        <TableCell key={`vendor-table-row-cell-${idx}`}>{cell}</TableCell>
      ))}
    </TableRow>
  );
};

const EditVendorDetails = ({
  editVendor,
  emissionCategory,
  naicsCode,
  scopeThreeCategory,
  status,
}) => {
  const { palette } = useTheme();

  return (
    <Grid container direction="column" spacing={2}>
      {status === "unconfirmed" && (
        <DetailsActionAlert
          color={palette.error.main}
          text="This vendor still hasn't filled out their survey. Send them a reminder email?"
        />
      )}
      <Grid item>
        <EmissionsCategorySelect
          value={emissionCategory}
          setValue={editVendor("emissionCategory")}
        />
      </Grid>
      {emissionCategory === "spend-based" && (
        <>
          <Grid item>
            <NaicsCodesAutocomplete
              editVendor={editVendor}
              naicsCode={naicsCode}
            />
          </Grid>
          <Grid item>
            <ScopeThreeCategorySelect
              scopeThreeCategory={scopeThreeCategory}
              setScopeThreeCategory={editVendor("scopeThreeCategory")}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const EditVendorFooter = ({
  editableVendor,
  setSelectedVendor,
  isSaveEnabled,
}) => {
  const { status, id } = editableVendor;

  const { palette } = useTheme();
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { saveLoading, onVendorSave } = useOnVendorSave(() =>
    setSelectedVendor(null)
  );

  const onDeleteClick = () => {
    updateCollectionDoc("vendors", id, { archived: true });
    return setSelectedVendor(null);
  };

  const isConfirmed = status === "confirmed";

  return (
    <Grid
      container
      justifyContent={isConfirmed ? "flex-end" : "space-between"}
      alignItems="center"
    >
      {!isConfirmed && (
        <Grid item>
          <IconButton onClick={() => onDeleteClick()}>
            <DeleteIcon style={{ color: palette.error.main }} />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <LoadingButton
          isLoading={saveLoading}
          onClick={() => onVendorSave(editableVendor)}
          variant="contained"
          color="primary"
          label="Save Edits"
          disabled={!isSaveEnabled}
        />
      </Grid>
    </Grid>
  );
};

const useEditVendorDetails = (selectedVendor, setSelectedVendor) => {
  const [editableVendor, setEditableVendor] = useState(selectedVendor);
  const { emissionCategory, naicsCode, scopeThreeCategory } = editableVendor;

  const editVendor = (field) => (value) =>
    editObjectData(setEditableVendor, field, value);

  const isSaveEnabled = () => {
    const findAreAllPropertiesSame = () => {
      const { emissionCategory: originalEmissionCategory } = selectedVendor;
      if (emissionCategory !== "spend-based") {
        return emissionCategory === originalEmissionCategory;
      }

      const {
        naicsCode: originalNaicsCode,
        scopeThreeCategory: originalScopeThreeCategory,
      } = selectedVendor;

      return (
        originalNaicsCode === naicsCode &&
        originalScopeThreeCategory === scopeThreeCategory
      );
    };

    const areAllPropertiesSame = findAreAllPropertiesSame();

    const areAllInputsComplete =
      !!emissionCategory &&
      (emissionCategory === "spend-based"
        ? naicsCode && scopeThreeCategory
        : true);

    return !areAllPropertiesSame && areAllInputsComplete;
  };

  return {
    content: <EditVendorDetails editVendor={editVendor} {...editableVendor} />,
    footer: (
      <EditVendorFooter
        editableVendor={editableVendor}
        setSelectedVendor={setSelectedVendor}
        isSaveEnabled={isSaveEnabled()}
      />
    ),
  };
};

const SurveyInvitation = ({ id }) => {
  const [invitation, setInvitation] = useState({});
  const [invitationSent, setInvitationSent] = useState(false);

  const { name, email = "" } = invitation;

  const editInvitation = (field) => (value) =>
    editObjectData(setInvitation, field, value);

  const onSendInvite = () =>
    fetchOurApi({
      path: "/companies/new-vendor-survey-invite",
      method: "POST",
      data: { id, name, email },
      callback: () => setInvitationSent(true),
    });

  return (
    <>
      {invitationSent ? (
        <Typography variant="h6" align="center">
          We've sent your invitation. Their information will be updated in your
          dashboard once they're filled out their survey.
        </Typography>
      ) : (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" align="center">
              We're estimating this vendor's emissions because they haven't
              filled out their vendor survey yet. Invite them?
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Vendor Contact Name"
              value={name}
              setValue={editInvitation("name")}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Vendor Contact Email"
              value={email}
              setValue={editInvitation("email")}
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={!name || !emailRegExpTest(email)}
                onClick={onSendInvite}
              >
                Invite Vendor
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const CompletedSurveysTimeline = ({ surveys }) => {
  const { palette } = useTheme();

  const CompletedSurveyText = ({ year, invitationEmail, dateCompleted }) => (
    <>
      <Typography variant="h4">{year}</Typography>
      <Typography variant="subtitle2">
        {invitationEmail} completed the survey for this vendor on{" "}
        {formatDate(dateCompleted)}
      </Typography>
    </>
  );

  if (surveys.length === 1) {
    const [survey] = surveys;
    return (
      <DefaultPaper style={{ backgroundColor: palette.backgroundGray.main }}>
        <Grid container spacing={2} wrap="nowrap" alignItems="center">
          <Grid item>
            <Avatar style={{ backgroundColor: palette.secondary.main }}>
              <CheckIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <CompletedSurveyText {...survey} />
          </Grid>
        </Grid>
      </DefaultPaper>
    );
  }

  return (
    <Timeline>
      {surveys.map(({ year, ...otherProps }, idx) => (
        <TimelineItem key={`completed-survey-timeline-item-${idx}`}>
          <TimelineOppositeContent>{year}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>
              <Avatar style={{ backgroundColor: palette.secondary.main }}>
                <CheckIcon />
              </Avatar>
              <TimelineConnector />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <DefaultPaper
              style={{ backgroundColor: palette.backgroundGray.main }}
            >
              <CompletedSurveyText {...otherProps} year={year} />
            </DefaultPaper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const IncompleteSurveyStepper = ({
  dateStarted,
  dateInvited,
  invitationEmail,
}) => {
  const redactEmail = () => {
    const [name, url] = invitationEmail.split("@");
    const redactedName = name.split("").map((letter, idx) => {
      if (idx <= 1 || name.length - 3 < idx) {
        return letter;
      }
      return "*";
    });

    return `${redactedName.join("")}@${url}`;
  };
  const redactedEmail = redactEmail();

  const surveySteps = [
    {
      completed: true,
      isActive: !dateStarted,
      label: `${redactedEmail} received the survey invitation on ${formatDate(
        dateInvited
      )}`,
    },
    {
      complete: !!dateStarted,
      isActive: !dateStarted,
      label: !!dateStarted
        ? `${redactedEmail} started the survey on ${formatDate(dateStarted)}`
        : `${redactedEmail} hasn't started the survey yet`,
    },
    {
      completed: false,
      isActive: !!dateStarted,
      label: `${redactedEmail} hasn't completed the survey yet.`,
    },
  ];
  const activeStep = surveySteps.findIndex(({ isActive }) => isActive);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {surveySteps.map(({ label, completed }, idx) => (
        <Step key={`incomplete-survey-step-${idx}`} completed={completed}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const SurveyDetailsView = ({ surveyStatus, id, surveys }) => {
  if (surveyStatus === "has-account") {
    return (
      <Typography variant="h6" align="center">
        You can't send a survey to this vendor since they already have an
        Aclymate account.
      </Typography>
    );
  }

  if (surveyStatus === "no-survey") {
    return <SurveyInvitation id={id} />;
  }

  const completedSurveys = surveys.filter(({ dateCompleted }) => dateCompleted);
  const incompleteSurvey = surveys.find(({ dateCompleted }) => !dateCompleted);

  return (
    <Grid container spacing={2} direction="column">
      {!!incompleteSurvey && (
        <>
          <Grid item>
            <Typography variant="h3">Current Survey</Typography>
          </Grid>
          <Grid item>
            <IncompleteSurveyStepper {...incompleteSurvey} />
          </Grid>
        </>
      )}
      {!!completedSurveys.length && (
        <>
          <Grid item>
            <Typography variant="h3">Completed Surveys</Typography>
          </Grid>
          <Grid item>
            <CompletedSurveysTimeline surveys={completedSurveys} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const VendorDetailsSlider = ({
  selectedVendor,
  setSelectedVendor,
  setSelectedTransaction,
}) => {
  const {
    id,
    transactions,
    name,
    status,
    emissionCategory,
    surveyStatus,
    surveys,
  } = selectedVendor;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { content, footer } = useEditVendorDetails(
    selectedVendor,
    setSelectedVendor
  );

  const emissionsDetailsArray =
    status === "confirmed"
      ? [
          {
            label: "Emissions",
            value: "emissions",
            content: (
              <EmissionsDetails
                setSelectedTransaction={setSelectedTransaction}
                emissions={transactions}
                type="vendors"
                name={name}
                chartEmissions={transactions}
                closeSelectedObjectSlider={() => setSelectedVendor(null)}
              />
            ),
          },
        ]
      : [];

  const views = [
    ...emissionsDetailsArray,
    { label: "Details", value: "details", content, footer },
    {
      label: "Survey",
      value: "survey",
      content: (
        <SurveyDetailsView
          surveyStatus={surveyStatus}
          id={id}
          surveys={surveys}
        />
      ),
    },
  ];

  const footerInputSubcategories = [
    { subcategory: emissionCategory },
    ...(emissionCategory === "spend-based"
      ? []
      : [{ subcategory: "spend-based" }]),
  ];

  return (
    <SettingsObjectDetailsSlider
      type="vendors"
      settingsObject={selectedVendor}
      onNameSave={(name) => updateCollectionDoc("vendors", id, { name })}
      views={views}
      setSelectedObject={setSelectedVendor}
      footerInputSubcategories={footerInputSubcategories}
    />
  );
};

const CheckedVendorsActionBlock = ({
  checkedVendors,
  setCheckedVendors,
  setMergeVendors,
}) => {
  const { palette } = useTheme();
  const { onVendorSave } = useOnVendorSave();
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [bulkVendorData, setBulkVendorData] = useState({});

  const { emissionCategory, naicsCode, scopeThreeCategory } = bulkVendorData;

  const editBulkVendorData = (field) => (value) =>
    editObjectData(setBulkVendorData, field, value);

  const buttonDisabled =
    !emissionCategory ||
    (emissionCategory === "spend-based" && (!naicsCode || !scopeThreeCategory));

  const onBulkVendorSave = async () => {
    setSaveLoading(true);
    await Promise.all(
      checkedVendors.map(
        async (vendor) => await onVendorSave({ ...vendor, ...bulkVendorData })
      )
    );
    return setCheckedVendors([]);
  };

  const onBulkVendorsDelete = async () => {
    setDeleteLoading(true);
    await Promise.all(
      checkedVendors.map(
        async ({ id }) =>
          await updateCollectionDoc("vendors", id, { archived: true })
      )
    );
    return setCheckedVendors([]);
  };

  const areAnyVendorsConfirmed = checkedVendors.find(
    ({ status }) => status === "confirmed"
  );

  const vendorsCountString = `${checkedVendors.length} vendor${letterSBoolean(
    checkedVendors
  )}`;

  return (
    <DefaultPaper>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="h6">Edit {vendorsCountString}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <EmissionsCategorySelect
                value={emissionCategory}
                setValue={editBulkVendorData("emissionCategory")}
              />
            </Grid>
            {emissionCategory === "spend-based" && (
              <>
                <Grid item>
                  <NaicsCodesAutocomplete
                    naicsCode={naicsCode}
                    editVendor={editBulkVendorData}
                  />
                </Grid>
                <Grid item>
                  <ScopeThreeCategorySelect
                    scopeThreeCategory={scopeThreeCategory}
                    setScopeThreeCategory={editBulkVendorData(
                      "scopeThreeCategory"
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <LoadingButton
                isLoading={saveLoading}
                onClick={onBulkVendorSave}
                disabled={buttonDisabled}
                label="Save Vendors"
                variant="contained"
                color="primary"
                size="small"
              />
            </Grid>
            {checkedVendors.length > 1 && (
              <Grid item>
                <Tooltip
                  title={`Merge ${checkedVendors.length} vendor${letterSBoolean(
                    checkedVendors
                  )}`}
                >
                  <IconButton onClick={() => setMergeVendors(true)}>
                    <CallMergeIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {!areAnyVendorsConfirmed && (
              <Grid item>
                {deleteLoading ? (
                  <CircularProgress />
                ) : (
                  <IconButton onClick={onBulkVendorsDelete}>
                    <Tooltip title={`Delete ${vendorsCountString}`}>
                      <DeleteIcon style={{ color: palette.error.main }} />
                    </Tooltip>
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};

const MergeVendors = ({
  checkedVendors,
  setCheckedVendors,
  setMergeVendors,
}) => {
  const updateVendorTransactions = useUpdateVendorTransactions();
  const { newCollectionDoc, updateCollectionDoc } = useCachedFirebaseCrud();

  const [saveLoading, setSaveLoading] = useState(false);
  const [mergedVendor, setMergedVendor] = useState(checkedVendors[0]);
  const { emissionCategory, naicsCode, naicsTitle, scopeThreeCategory, name } =
    mergedVendor;
  const editMergedVendor = (field) => (value) =>
    editObjectData(setMergedVendor, field, value);

  const isSaveEnabled =
    name &&
    !!emissionCategory &&
    (emissionCategory === "spend-based"
      ? naicsCode && scopeThreeCategory
      : true);

  const uniqueSelectedEmissionCategories = [
    ...new Set(checkedVendors.map(({ emissionCategory }) => emissionCategory)),
  ];

  const onMergeVendors = async () => {
    setSaveLoading(true);

    const aliases = [
      ...new Set(
        checkedVendors.flatMap(({ name, aliases = [] }) => [name, ...aliases])
      ),
    ];
    const newVendorId = await newCollectionDoc("vendors", {
      emissionCategory,
      naicsCode,
      naicsTitle,
      scopeThreeCategory,
      name,
      aliases,
    });

    const allTransactions = checkedVendors.flatMap(
      ({ transactions }) => transactions
    );

    await Promise.all([
      updateVendorTransactions({
        ...mergedVendor,
        id: newVendorId,
        transactions: allTransactions,
      }),
      ...checkedVendors.map(
        async ({ id: vendorId }) =>
          await updateCollectionDoc("vendors", vendorId, { archived: true })
      ),
    ]);

    setCheckedVendors([]);
    return setMergeVendors(false);
  };

  return (
    <SettingsObjectDetailsSlider
      type="vendors"
      settingsObject={mergedVendor}
      objectName={name}
      editSettingsObject={editMergedVendor}
      setSelectedObject={setMergeVendors}
      leftContent={
        <Container maxWidth="sm">
          <Grid container direction="column" spacing={2}>
            {checkedVendors.map(({ name, emissionCategory }, idx) => (
              <Grid
                item
                key={`merge-vendor-${idx}`}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <CategoriesAvatar subcategory={emissionCategory} />
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ color: "white" }}>
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      }
      views={[
        {
          label: "Details",
          value: "details",
          content: (
            <EditVendorDetails
              editVendor={editMergedVendor}
              {...mergedVendor}
            />
          ),
          footer: (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
            >
              {uniqueSelectedEmissionCategories.length > 1 && (
                <Grid item>
                  <Typography variant="body2" color="error" align="right">
                    Warning: you are merging multiple transactions with
                    different categories.
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <LoadingButton
                  isLoading={saveLoading}
                  onClick={onMergeVendors}
                  variant="contained"
                  color="primary"
                  label={`Merge ${checkedVendors.length} vendor${letterSBoolean(
                    checkedVendors
                  )}`}
                  disabled={!isSaveEnabled}
                />
              </Grid>
            </Grid>
          ),
        },
      ]}
    />
  );
};

const VendorsViewBlock = ({ viewLoading }) => {
  const { vendorsLoading, displayVendors, vendorAlerts } = useVendorData();
  const { displayUnit, convertCarbonUnits } = useContext(PlatformLayoutContext);

  const { filterChips, filterByChips } = useStatusFilterChips(
    displayVendors,
    vendorStatuses
  );

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [checkedVendors, setCheckedVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorSearchString, setVendorSearchString] = useState("");
  const [filterSubcategories, setFilterSubcategories] = useState([]);
  const [showNewVendorInput, setShowNewVendorInput] = useState(false);
  const [mergeVendors, setMergeVendors] = useState(false);

  const tableColumns = [
    makeColumnObj("SELECT", "isChecked"),
    makeColumnObj("STATUS", "severity", true),
    makeColumnObj("NAME", "name", true),
    makeColumnObj("EMISSION CATEGORY", "emissionCategory", true),
    makeColumnObj(`TOTAL ${displayUnit.toUpperCase()} CO2`, "tonsCo2e", true),
    makeColumnObj(
      `AVG. ${displayUnit.toUpperCase()} PER TRANSACTION`,
      "avgTonsCo2ePerTransaction",
      true
    ),
    makeColumnObj("TOTAL # OF TRANSACTIONS", "transasctionsCount", true),
    makeColumnObj("SCORE", "vendorScore", true),
  ];

  const filterRows = [
    {
      icon: faIndustry,
      title: "Emission Category",
      type: "subcategory",
      filterComponent: (
        <EmissionsCategorySelect
          multiple
          value={filterSubcategories}
          setValue={setFilterSubcategories}
        />
      ),
    },
  ];

  const applyFilters = () => {
    const filterBySubcategory = ({ emissionCategory }) =>
      filterSubcategories.length
        ? !!filterSubcategories.find(({ value }) => value === emissionCategory)
        : true;

    const filterBySearchString = ({ name }) =>
      name.toLowerCase().includes(vendorSearchString.toLowerCase());

    return displayVendors.filter(
      (vendor) =>
        filterByChips(vendor) &&
        filterBySubcategory(vendor) &&
        filterBySearchString(vendor)
    );
  };

  const filteredVendors = applyFilters();

  const chartFilteredVendors = displayVendors.filter(
    ({ transactions = [], status }) =>
      status === "confirmed" && transactions.length
  );

  const selectVendorFromGraph = (id) =>
    setSelectedVendor(chartFilteredVendors.find((vendor) => vendor.id === id));

  return (
    <>
      {showNewVendorInput && (
        <VendorInputForm setOpen={setShowNewVendorInput} />
      )}
      {mergeVendors && (
        <MergeVendors
          checkedVendors={checkedVendors}
          setCheckedVendors={setCheckedVendors}
          setMergeVendors={setMergeVendors}
        />
      )}
      {selectedVendor && (
        <VendorDetailsSlider
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          setSelectedTransaction={setSelectedTransaction}
        />
      )}
      {selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      {!!displayVendors.length ? (
        <DashboardViewLayout
          type="vendors"
          viewLoading={vendorsLoading || viewLoading}
          title="Vendors"
          subtitle={
            displayVendors.length
              ? `You have ${
                  displayVendors.length
                } vendors that have contributed ${formatDecimal(
                  convertCarbonUnits(sumTonsCo2e(displayVendors))
                )} ${displayUnit} to your balance`
              : "`You haven't loaded any vendors yet"
          }
          chips={filterChips}
          filterPopperProps={{
            filterRows,
            resetFilters: () => setFilterSubcategories([]),
            title: "Filter Vendors",
          }}
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowNewVendorInput(true)}
            >
              Add New Vendors
            </Button>
          }
          premiumActions={[
            <TextField
              label="Search Vendors"
              value={vendorSearchString}
              setValue={setVendorSearchString}
            />,
          ]}
          alerts={vendorAlerts}
          graph={
            !!chartFilteredVendors.length && (
              <VendorsGraphs
                vendors={[...chartFilteredVendors].sort(
                  (a, b) => b.tonsCo2e - a.tonsCo2e
                )}
                setSelectedVendor={selectVendorFromGraph}
              />
            )
          }
          tableAction={
            !!checkedVendors.length && (
              <CheckedVendorsActionBlock
                setMergeVendors={setMergeVendors}
                checkedVendors={checkedVendors}
                setCheckedVendors={setCheckedVendors}
              />
            )
          }
          table={
            <SortableTable
              rows={[...filteredVendors].sort(
                (a, b) => b.severity - a.severity
              )}
              rowComponent={(vendor) => (
                <VendorsTableRow
                  checkedVendors={checkedVendors}
                  setCheckedVendors={setCheckedVendors}
                  vendor={vendor}
                  setSelectedVendor={setSelectedVendor}
                />
              )}
              columns={tableColumns}
            />
          }
        />
      ) : (
        <MissingVendors setShowNewVendorInput={setShowNewVendorInput} />
      )}
    </>
  );
};

const VendorsView = (props) => (
  <ErrorBoundary>
    <VendorsViewBlock {...props} />
  </ErrorBoundary>
);
export default VendorsView;
