import React, { useState, useEffect, createContext, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";

import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
} from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { CompanyOnboardingInput } from "@aclymatepackages/modules";

import SignInForm from "./SignInForm";
import ResendVerificationEmailButton from "./ResendVerificationEmailButton";

import OnboardingImageLayoutBackdrop from "../layouts/OnboardingImageLayoutBackdrop";
import PdlCompanySelect from "../inputs/PdlCompanySelect";
import PlacesAutocomplete from "../inputs/autocomplete/PlacesAutocomplete";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { useAuth } from "../../helpers/firebase";
import { sendExistingCompanyAdminEmail } from "../../helpers/components/accountAccess";
import { analyticsTrack } from "../../helpers/analytics";

const OnboardingContext = createContext();

const fetchExistingCompanyData = async (dbId) =>
  await fetchOurApi({
    path: "/verification/fetch-existing-db-company-data",
    method: "POST",
    data: { dbId },
    callback: ({ companyData }) => companyData,
  });

const useVerifyAccount = () => {
  const history = useHistory();
  const [user] = useAuth();

  const { setVerifyAccountLoading } = useContext(OnboardingContext);

  const platformRedirectCallback = ({ companyId }) => {
    window.sessionStorage.setItem("accountId", `v2-companies-${companyId}`);
    analyticsTrack("finished onboarding");
    return history.push("/platform/company/onboarding");
  };

  return async (action, data = {}) => {
    setVerifyAccountLoading(true);
    return await fetchOurApi({
      path: `/verification/${action}`,
      method: "POST",
      data: { user, ...data },
      callback: platformRedirectCallback,
    });
  };
};

const ExistingCompanyWithUserError = ({ existingCompanyWithUserError }) => {
  const { palette } = useTheme();

  return (
    <>
      {existingCompanyWithUserError && (
        <Grid item>
          <Typography variant="h6" color={palette.error.main} align="center">
            An account already exists for this company. We've let this account's
            administrator know that you've requested access to their account.
            You'll be informed by email once they're approved you
          </Typography>
        </Grid>
      )}
    </>
  );
};

const PageLoadingAnimation = ({ caption }) => (
  <Box display="flex" justifyContent="center">
    <Grid container spacing={2} direction="column" alignItems="center">
      <Grid item>
        <AnimatedLogo />
      </Grid>
      {caption && (
        <Grid item>
          <Typography variant="h6" align="center">
            {caption}
          </Typography>
        </Grid>
      )}
    </Grid>
  </Box>
);

const OnboardingSiginIn = () => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountAccessError, setAccountAccessError] = useState("");

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h4" color="secondary">
          Welcome back
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Please log into your account to continue onboarding
        </Typography>
      </Grid>
      <SignInForm
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        password={password}
        setPassword={setPassword}
        setAccountAccessError={setAccountAccessError}
      />
      <Grid item>
        <Typography align="center" color="error">
          {accountAccessError}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ErrorMessage = ({ status = "error", email }) => {
  const statusMessages = {
    error:
      "We couldn't find a company to verify. Please make sure that you have the right URL from the email we sent you",
    expired:
      "Your verification token is expired. Click the button below to generate a new verification token",
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6" align="center">
          {statusMessages[status]}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <ResendVerificationEmailButton email={email} />
      </Grid>
    </Grid>
  );
};

const OnboardingInputHeader = () => (
  <>
    <Grid item>
      <Typography variant="h3" color="textPrimary" align="center">
        Tell us about your company
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h6" color="textSecondary" align="center">
        We'll use this information to calculate your preliminary carbon
        footprint
      </Typography>
    </Grid>
  </>
);

const PdlSelectStep = ({
  setCompanyName,
  origin,
  companyId,
  referralPartner,
  setSelectedCompanyId,
}) => {
  const [user] = useAuth();
  const { uid, displayName, email } = user;

  const verifyAccount = useVerifyAccount();
  const verifyCompanyFromPdlId = async (pdlCompanyId, dbId) =>
    await verifyAccount("verify-company-from-pdl-id", {
      pdlCompanyId,
      companyId: dbId,
    });

  const [existingCompanyWithUserError, setExistingCompanyWithUserError] =
    useState(false);

  const createNewUnverifiedCompany = async () =>
    await fetchOurApi({
      path: `/verification/create-new-unverified-company`,
      method: "POST",
      data: { uid, displayName, email, referralPartner },
    });

  const onSelectCompany = async ({ dbId, pdlId }) => {
    const handleDbIdCompanySelected = async () => {
      if (!origin) {
        fetchOurApi({
          path: `/verification/delete-unneeded-onboarding-company`,
          method: "POST",
          data: { companyId, uid },
        });
      }

      const existingCompany = await fetchExistingCompanyData(dbId);
      const { peopleDataLabsCompanyId, uids, name } = existingCompany;

      if (uids) {
        sendExistingCompanyAdminEmail(existingCompany, user);
        return setExistingCompanyWithUserError(true);
      }

      if (peopleDataLabsCompanyId) {
        return verifyCompanyFromPdlId(peopleDataLabsCompanyId, dbId);
      }

      setSelectedCompanyId(dbId);
      return setCompanyName(name);
    };

    if (dbId) {
      return handleDbIdCompanySelected();
    }

    if (!!origin) {
      await createNewUnverifiedCompany();
    }

    return verifyCompanyFromPdlId(pdlId, companyId);
  };

  const onCompanyNotFound = (name) => {
    if (!!origin) {
      createNewUnverifiedCompany();
    }

    return setCompanyName(name);
  };

  return (
    <Grid container flexDirection="column" spacing={2}>
      <OnboardingInputHeader />
      <Grid item>
        <PdlCompanySelect
          onCompanyNotFound={onCompanyNotFound}
          onCompanySelected={onSelectCompany}
        />
      </Grid>
      <ExistingCompanyWithUserError
        existingCompanyWithUserError={existingCompanyWithUserError}
      />
    </Grid>
  );
};

const CompanyOnboardingForm = ({
  origin,
  companyId,
  existingCompanyName,
  referralPartner,
}) => {
  const verifyAccount = useVerifyAccount();

  const [companyName, setCompanyName] = useState(existingCompanyName);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId);

  const onFormComplete = async (inputData) =>
    await verifyAccount("verify-company-input-data", {
      ...inputData,
      companyId: selectedCompanyId,
    });

  if (!companyName) {
    return (
      <PdlSelectStep
        setCompanyName={setCompanyName}
        origin={origin}
        companyId={companyId}
        referralPartner={referralPartner}
        setSelectedCompanyId={setSelectedCompanyId}
      />
    );
  }

  return (
    <CompanyOnboardingInput
      companyName={companyName}
      header={<OnboardingInputHeader />}
      onFormComplete={onFormComplete}
      PlacesAutocomplete={PlacesAutocomplete}
    />
  );
};

const CompanyNameConfirm = ({
  dbCompanyData,
  setShowCompanyOnboarding,
  setExistingCompanyName,
}) => {
  const [user] = useAuth();
  const { uid } = user;

  const verifyAccount = useVerifyAccount();

  const {
    name,
    origin,
    id: companyId,
    peopleDataLabsCompanyId,
  } = dbCompanyData || {};

  const onRightCompanyMatched = async () => {
    if (!peopleDataLabsCompanyId) {
      setExistingCompanyName(name);
      return setShowCompanyOnboarding(true);
    }

    return await verifyAccount("verify-existing-pdl-company", { companyId });
  };

  const onNotTheRightCompany = () => {
    if (!!origin) {
      fetchOurApi({
        path: `/verification/remove-company-verification-props`,
        method: "POST",
        data: { companyId, uid },
      });
    }

    return () => setShowCompanyOnboarding(true);
  };

  return (
    <OnboardingImageLayoutBackdrop
      title="Welcome Back"
      subtitle={`It looks like you're creating an account for ${name}. Is this right?`}
      action={
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={onRightCompanyMatched}
            >
              Yes, this is my company
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={onNotTheRightCompany}>
              No, I'm with another company
            </Button>
          </Grid>
        </Grid>
      }
      image="/images/platform/onboarding-svg.svg"
      backdropProps={{
        backgroundColor: "rgba(255, 255, 255, 0.95)",
      }}
    />
  );
};

const CompanyOnboardingFlow = ({ dbCompanyData }) => {
  const { name, origin, id: companyId, referralPartner } = dbCompanyData || {};

  const [showCompanyOnboarding, setShowCompanyOnboarding] = useState(false);
  const [existingCompanyName, setExistingCompanyName] = useState("");

  return (
    <>
      {showCompanyOnboarding || !name ? (
        <CompanyOnboardingForm
          origin={origin}
          companyId={companyId}
          existingCompanyName={existingCompanyName}
          referralPartner={referralPartner}
        />
      ) : (
        <CompanyNameConfirm
          dbCompanyData={dbCompanyData}
          setShowCompanyOnboarding={setShowCompanyOnboarding}
          setExistingCompanyName={setExistingCompanyName}
        />
      )}
    </>
  );
};

const OnboardingComponent = () => {
  const token = new URLSearchParams(window.location.search).get("token");

  const [user, userLoading] = useAuth();
  const { uid, email } = user || {};

  const { verifyAccountLoading } = useContext(OnboardingContext);

  const [verificationStatus, setVerificationStatus] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const fetchVerificationStatus = async () =>
      await fetchOurApi({
        path: "/verification/check-verification-status",
        method: "POST",
        data: { uid, token },
        callback: (res) => {
          const { status, companyData } = res;

          if (status === "ready") {
            setCompanyData(companyData);
          }
          return setVerificationStatus(status);
        },
      });

    if (!userLoading && uid && !verificationStatus) {
      fetchVerificationStatus();
    }
  }, [userLoading, uid, verificationStatus, token]);

  const switchOnboardingComponents = () => {
    switch (verificationStatus) {
      case "ready":
        return <CompanyOnboardingFlow dbCompanyData={companyData} />;
      case "redirect":
        return <Redirect to="/platform/company/dashboard" />;
      default:
        return <ErrorMessage status={verificationStatus} email={email} />;
    }
  };

  const displayContent = () => {
    if (!user && !userLoading) {
      return <OnboardingSiginIn />;
    }

    if (userLoading || !verificationStatus || verifyAccountLoading) {
      return (
        <PageLoadingAnimation
          caption={
            !!verifyAccountLoading &&
            "Please wait while we build your new account"
          }
        />
      );
    }

    return switchOnboardingComponents();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Container maxWidth="sm">{displayContent()}</Container>
    </Box>
  );
};

const OnboardingContextProvider = ({ children }) => {
  const [verifyAccountLoading, setVerifyAccountLoading] = useState(false);

  return (
    <OnboardingContext.Provider
      value={{ verifyAccountLoading, setVerifyAccountLoading }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

const Onboarding = () => (
  <OnboardingContextProvider>
    <OnboardingComponent />
  </OnboardingContextProvider>
);

export default Onboarding;
