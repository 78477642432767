import React from "react";

import {
  Grid,
  Typography,
  Divider,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { mergeDarkTheme } from "@aclymatepackages/themes";

const WarningPopup = ({
  type,
  warningTitle,
  secondaryAction,
  warningInput,
}) => {
  const { palette } = useTheme();

  return (
    <DefaultPaper
      style={{
        backgroundColor:
          type === "success" ? palette.secondary.main : palette.error.main,
      }}
    >
      <ThemeProvider theme={mergeDarkTheme}>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            sm={12}
            container
            spacing={2}
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item>
              {type === "success" ? (
                <CheckIcon fontSize="large" style={{ color: "white" }} />
              ) : (
                <WarningIcon fontSize="large" style={{ color: "white" }} />
              )}
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ color: "white" }}>
                {warningTitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            {warningInput}
          </Grid>
          {secondaryAction && (
            <>
              <Grid
                item
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <Divider style={{ backgroundColor: "white" }} />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    style={{ color: "white" }}
                  >
                    OR
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider style={{ backgroundColor: "white" }} />
                </Grid>
              </Grid>
              <Grid item>{secondaryAction}</Grid>
            </>
          )}
        </Grid>
      </ThemeProvider>
    </DefaultPaper>
  );
};
export default WarningPopup;
