import React from "react";

import { InputAdornment } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { TextField } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";

const DollarInput = ({ value, setValue, ...otherProps }) => {
  return (
    <TextField
      value={formatDecimal(value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon />
          </InputAdornment>
        ),
      }}
      setValue={(value) => setValue(Number(value.replaceAll(",", "")))}
      {...otherProps}
    />
  );
};
export default DollarInput;
