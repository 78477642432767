import { useState, useContext } from "react";
import dayjs from "dayjs";
import { Promise as bluebird } from "bluebird";
import { useSWRConfig } from "swr";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { processTransactionsWithVendors } from "@aclymatepackages/calcs/vendors";
import { isObjectEmpty } from "@aclymatepackages/other-helpers";

import useCsvUploader from "./csvUploader";

import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";
import useEmissionsContext from "../../helpers/contexts/emissions";
import { useSharedFormLoading } from "../../helpers/components/inputs";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import {
  useAccountData,
  useCachedDisplayData,
  useCachedFirebaseCrud,
} from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Vendor", value: "vendor" },
  { label: "Value", value: "value" },
];

const requiredFields = ["Date", "Vendor", "Value"];

const knownFields = [
  { header: "date", field: "date" },
  { header: "description", field: "vendor" },
  { header: "vendor", field: "vendor" },
  { header: "memo", field: "vendor" },
  { header: "value", field: "value" },
  { header: "total", field: "value" },
  { header: "amount", field: "value" },
];

const fieldValidator = (headers) => {
  const fieldsBoolean = requiredFields.reduce(
    (booleanAcc, requiredField) =>
      booleanAcc &&
      headers.find(({ field }) => requiredField.toLowerCase() === field),
    true
  );

  return {
    success: fieldsBoolean,
    message:
      !fieldsBoolean &&
      `The fields Date, Vendor,${
        requiredFields.includes("Category") ? " Category," : ""
      } and Value are required for this CSV. Exclude Category to select your own categories for each transaction.`,
  };
};

const parseTransactionValue = (value) => {
  if (!value) {
    return null;
  }

  if (!isNaN(Number(value))) {
    return Number(value);
  }

  const valueNumbers = value.match(/[0-9.-]+/gm) || [];
  if (!valueNumbers.length) {
    return null;
  }

  const joinedNumber = valueNumbers.join("");

  const matchParentheses = value.match(/\([0-9,.]+\)/gm);

  return matchParentheses?.length
    ? Number(joinedNumber * -1)
    : Number(joinedNumber);
};

const useProcessTransactions = ({
  fileInfo,
  setFileInfo,
  setOpen,
  setTransactionsResponseCount,
  setTotalTransactionsCount,
}) => {
  const { csvId, name: fileName } = fileInfo;

  const { activateSnackbar } = useContext(PlatformLayoutContext);
  const { transactions: existingTransactions } = useEmissionsContext();

  const [{ geography, startDate }] = useAccountData();
  const [vendors] = useCachedDisplayData("vendors");

  const { setFormLoading } = useSharedFormLoading();
  const { batchNewCollectionDocs } = useCachedFirebaseCrud();

  const { mutate } = useSWRConfig();

  const preProcessTransactions = ({ data: rawTransactions, csvId }) => {
    editObjectData(setFileInfo, "csvId", csvId);

    const realTransactions = rawTransactions.filter(
      ({ date, value }) => date && value
    );

    return realTransactions.map((transaction) => {
      const { date, value: rawValue, vendor } = transaction;

      const sharedProps = {
        ...transaction,
        rawValue,
        value: parseTransactionValue(rawValue),
      };

      if (startDate && dayjs(date).isBefore(startDate)) {
        return { ...sharedProps, beforeStartDate: true };
      }

      const existingTransaction = existingTransactions.find(
        (existingTransaction) =>
          dayjs(existingTransaction.date).isSame(dayjs(date, "day")) &&
          existingTransaction.value === rawValue &&
          existingTransaction.vendor === vendor
      );
      if (existingTransaction) {
        return { ...sharedProps, duplicate: true };
      }

      return {
        ...sharedProps,
        archived: false,
        source: "csv-upload",
        importDate: new Date(),
        csvId,
        csvName: fileName,
      };
    });
  };

  const sendTransactionToBackend = async (transaction) => {
    const accountId = window.sessionStorage.getItem("accountId");
    setTransactionsResponseCount((currentCount) => currentCount + 1);

    return await fetchOurApi({
      path: "/transactions/sort",
      method: "POST",
      data: {
        transaction,
        accountId,
        companyAddress: geography.address,
      },
      callback: (response) => {
        setTransactionsResponseCount((currentCount) => currentCount + 1);
        const { success, transaction: responseTransaction } = response;
        if (success) {
          const dummyId = Math.random() * Date.now();
          return { ...responseTransaction, id: dummyId };
        }

        console.log("no success");
        return null;
      },
    });
  };

  const processTransactionsData = async ({ data }) => {
    setFormLoading(true);

    const taggedTransactions = preProcessTransactions({ data, csvId });

    const readyTransactions = taggedTransactions.filter(({ source }) => source);
    analyticsTrack("Transactions Loaded", {
      numberOfTransactions: readyTransactions.length,
    });

    const tooOldTransactions = taggedTransactions.filter(
      ({ beforeStartDate }) => beforeStartDate
    );
    const duplicateTransactions = taggedTransactions.filter(
      ({ duplicate }) => duplicate
    );

    setTotalTransactionsCount(readyTransactions.length);

    const transactionsWithVendors = await processTransactionsWithVendors(
      readyTransactions,
      vendors,
      batchNewCollectionDocs
    );

    const newDbTransactions = await bluebird.map(
      transactionsWithVendors,
      sendTransactionToBackend,
      { concurrency: 10 }
    );

    const filteredDbTransactions = newDbTransactions.filter(
      (transaction) => transaction && !isObjectEmpty(transaction)
    );

    mutate("transactions", (existingTransactions) => [
      ...existingTransactions,
      ...filteredDbTransactions,
    ]);

    const buildRejectedTransactionsSentences = () => {
      const duplicateTransactionsSentence = !!duplicateTransactions.length
        ? ` ${duplicateTransactions.length} transactions were excluded because they were duplicates of already loaded transactions`
        : "";
      const tooOldTransactionsSentence = !!tooOldTransactions.length
        ? ` ${tooOldTransactions.length} transactions were excluded because they happened before your start date.`
        : "";
      return `${duplicateTransactionsSentence}${tooOldTransactionsSentence}`;
    };

    const snackbarMessage = `${
      newDbTransactions.length
    } transactions were added to your account.${buildRejectedTransactionsSentences()}`;

    activateSnackbar({ message: snackbarMessage, alert: "success" });
    setFormLoading(false);
    return setOpen(false);
  };

  return processTransactionsData;
};

const useTransactionsUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
  const [transactionsResponseCount, setTransactionsResponseCount] = useState(0);

  const processTransactionsData = useProcessTransactions({
    fileInfo,
    setFileInfo,
    setTransactionsResponseCount,
    setTotalTransactionsCount,
    setOpen,
  });

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData: processTransactionsData,
    fieldValidator,
    docType: "transactions",
    firstStepText:
      "Upload a CSV of your transactions and we'll automatically sort out the ones that you can offset.",
    requiredFields,
  });

  return [
    csvUploaderSteps,
    (transactionsResponseCount / totalTransactionsCount) * 100,
  ];
};
export default useTransactionsUploader;
