import { useState, useEffect } from "react";
import { useParams } from "react-router";

import fetchAclymateApi from "@aclymatepackages/fetch-aclymate-api";

import { auth, useAccountData } from "../firebase";
import { getAccountCollectionAndId } from "../otherHelpers";

export const fetchAdminApi = async ({ url, data = {}, callback }) => {
  const { id: companyId } = getAccountCollectionAndId();

  const postHeaders = {
    "Content-Type": "application/json",
    "X-auth-token": `companyId:${companyId}-${process.env.REACT_APP_ENVIRONMENT}`,
  };

  /* USE THIS URL FOR DEV: "http://localhost:8500/admin-test-91820/us-central1/adminApi" */

  const baseFetchHost =
    process.env.REACT_APP_ENVIRONMENT !== "production"
      ? "https://us-central1-admin-test-91820.cloudfunctions.net/adminApi"
      : "https://us-central1-aclymate-admin.cloudfunctions.net/adminApi";

  const { method = null, body = null } = data;
  const fullUrl = `${baseFetchHost}${url}`;
  const headers = {
    "Content-Type": "application/json",
    "X-auth-token": `companyId:${companyId}-${process.env.REACT_APP_ENVIRONMENT}`,
  };

  const fetchData = body
    ? {
        method,
        body: JSON.stringify(body),
        headers: { ...postHeaders, ...headers },
      }
    : { method, body, headers };

  return await fetch(fullUrl, fetchData)
    .then((res) => res.json())
    .then((json) => (callback ? callback(json) : json))
    .catch((e) => {
      console.log("error: " + JSON.stringify(e));
      throw Error(e);
    });
};

export const baseFetchHost =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/aclymate-test-74e62/us-central1/app"
    : process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

export const fetchOurApi = async ({ user, accountId = "", ...otherProps }) => {
  const { currentUser } = auth || {};

  const lsAccountId = window.sessionStorage.getItem("accountId");

  const usableUser = currentUser || user;
  const useableAccountId = lsAccountId || accountId;

  return await fetchAclymateApi({
    baseFetchHost,
    user: usableUser,
    accountId: useableAccountId,
    ...otherProps,
  });
};

export const useApiData = ({ path, method, data, isLoading }) => {
  const { companyId } = useParams();

  const [apiData, setApiData] = useState(undefined);
  const [apiDataLoading, setApiDataLoading] = useState(true);

  useEffect(() => {
    const structuredCompanyId = `v2-companies-${companyId}`;
    const accountId = window.sessionStorage.getItem("accountId");

    if (apiDataLoading && !isLoading) {
      fetchOurApi({
        accountId: accountId || structuredCompanyId,
        path,
        method,
        data,
        callback: (response) => {
          setApiData(response);
          return setApiDataLoading(false);
        },
      });
    }
  }, [path, method, data, companyId, apiDataLoading, isLoading]);

  return [apiData, apiDataLoading];
};

export const fetchPlaceId = async (description) =>
  await fetchOurApi({
    path: `/google-maps/place-id/${description}`,
    method: "GET",
    callback: ({ candidates }) => candidates[0]?.place_id,
  });

export const fetchDirectionsMileage = async (to, from) => {
  const accountId = window.sessionStorage.getItem("accountId");

  return await fetchOurApi({
    accountId,
    path: "/calcs/cars/directions-mileage",
    method: "POST",
    data: { to, from },
    callback: ({ totalMileage }) => totalMileage,
  });
};

export const fetchTotalMileageCarbon = async (mileage, editTransaction) =>
  await fetchOurApi({
    path: "/calcs/cars/total-mileage-carbon",
    method: "POST",
    data: { mileage: Number(mileage) },
    callback: ({ tonsCo2e }) => editTransaction(tonsCo2e),
  });

export const findPropertyDetails = async ({
  description: locationDescription,
  city,
  state,
  zipCode,
}) =>
  await fetchOurApi({
    path: "/integrations/realty-mole-property/property-details",
    method: "POST",
    data: {
      locationDescription,
      city,
      state,
      zipCode,
    },
    callback: (res) => res,
  });

export const fetchUserWithEmail = async (email) =>
  await fetchOurApi({
    path: "/onboarding/check-email",
    method: "POST",
    data: { email },
    callback: ({ success, data }) => {
      if (success) {
        return data;
      }
      return false;
    },
  });

export const useFetchUtilitiesCarbon = () => {
  const [companyData] = useAccountData();
  const { zipCode } = companyData?.geography?.address || {};

  return async (
    unitValue,
    billUnit,
    fuelType,
    electricCarbonIntensityTonsPerMwh,
    date
  ) => {
    if (unitValue && zipCode) {
      const getUtilitiesUnit = () => {
        const fuelTypeUnits = {
          electricity: "kwh",
          propane: "gallons-propane",
          wood: "cords",
          heatingOil: "gallons-heating-oil",
        };
        return fuelTypeUnits[fuelType];
      };

      return await fetchOurApi({
        path: `/calcs/utilities/carbon-from-unit-value`,
        method: "POST",
        data: {
          unitValue,
          billUnit: fuelType === "naturalGas" ? billUnit : getUtilitiesUnit(),
          fuelType,
          zipCode,
          electricCarbonIntensityTonsPerMwh,
          date,
        },
        callback: ({ tonsCo2 }) => tonsCo2,
      });
    }
    return 0;
  };
};

export const queryForExistingCompany = async (field, value) =>
  await fetchOurApi({
    path: "/onboarding/query-companies",
    method: "POST",
    data: {
      field,
      value,
    },
    callback: ([companyData]) => companyData,
  });

export const deleteOauthUser = async (email) =>
  await fetchOurApi({
    path: "/onboarding/delete-oauth-user",
    method: "POST",
    data: {
      email,
    },
  });

export const sendSupportEmail = async ({
  name,
  email,
  companyName,
  subject,
  message,
  callback,
}) =>
  await fetchOurApi({
    path: "/sendgrid/support-inquiry",
    method: "POST",
    data: { name, email, companyName, subject, message },
    callback,
  });

export const fetchZipCodeEGrid = async ({ zipCode }) =>
  await fetchOurApi({
    path: "/calcs/utilities/egrid-from-zip-code",
    method: "POST",
    data: { zipCode },
    callback: ({ eGrid }) => eGrid,
  });

export const fetchCarbonFromGallons = async ({ gallons, fuelType = "gas" }) =>
  await fetchOurApi({
    path: "/calcs/cars/carbon-from-gallons",
    method: "POST",
    data: {
      gallons,
      fuelType,
    },
    callback: ({ tonsCo2 }) => tonsCo2,
  });

export const fetchFuelBillCarbon = async ({
  billValue,
  billDate,
  gasStation,
}) =>
  await fetchOurApi({
    path: "/calcs/cars/fuel-bill-carbon",
    method: "POST",
    data: {
      fuelBill: {
        billValue,
        billDate: billDate || new Date(),
        gasStation,
      },
    },
    callback: ({ tonsCo2 }) => tonsCo2,
  });

export const newAuthUser = async (
  { email, password, displayName },
  callback = (res) => res
) =>
  await fetchOurApi({
    path: "/onboarding/new-auth-user",
    method: "POST",
    data: { email, password, displayName },
    callback,
  });
