import React, { useState, useContext } from "react";
import dayjs from "dayjs";

import {
  Avatar,
  Box,
  Grid,
  Button,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
  StyledEngineProvider,
  useTheme,
  ThemeProvider,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningIcon from "@mui/icons-material/Warning";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCarBus } from "@fortawesome/pro-solid-svg-icons";

import { faLaptopHouse } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoadingButton, TextField } from "@aclymatepackages/atoms";
import {
  formatDate,
  formatDecimal,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import {
  editObjectData,
  addDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  findMonthlyUtilitiesEmissionsFromCommuteSchedules,
  buildMonthlyCommuteEmissionsArray,
  findEmployeesCommuteSchedules,
} from "@aclymatepackages/calcs/recurring";
import { STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES } from "@aclymatepackages/constants";

import DatePopper from "./DatePopper";
import EmployeeCommuteSchedulesView from "./EmployeeCommuteSchedulesView";
import EmployeesGraph from "./EmployeesGraph";

import EmissionsDetailsBlock from "../../EmissionsDetails";
import SettingsObjectDetailsSlider from "../../SettingsObjectDetailsSlider";
import EditRowTransactions from "../../EditRowTransactions";

import ErrorBoundary from "../../../../atoms/ErrorBoundary";
import useStatusFilterChips from "../../../../hooks/statusFilterChips";
import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import { MissingEmployees } from "../../../../layouts/missingSettingsLayouts";
import SendEmployeeSurveyButton from "../../../../atoms/buttons/SendEmployeeSurveyButton";
import Link from "../../../../atoms/mui/Link";
import DatePicker from "../../../../atoms/mui/DatePicker";
import AddEmployeesInputs from "../../../../inputs/employees/AddEmployeesInputs";
import EditableVehiclesCards from "../../../../inputs/vehicles/EditableVehiclesCards";
import SortableTable, {
  makeColumnObj,
} from "../../../../modules/tables/SortableTable";

import {
  buildNewEmployeeStatusLinks,
  useEmployeesData,
  sendEmployeeSurvey,
} from "../../../../../helpers/components/employees";
import { useAllVehicleTypes } from "../../../../../helpers/components/vehicles";
import { useAllOfficeTypes } from "../../../../../helpers/components/offices";

import {
  generateSecureId,
  getAccountCollectionAndId,
  truncateText,
} from "../../../../../helpers/otherHelpers";
import {
  useCachedFirebaseCrud,
  useAccountData,
} from "../../../../../helpers/firebase";
import useEmissionsContext from "../../../../../helpers/contexts/emissions";
import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import { useStarterTierSubscriptionFlags } from "../../../../../helpers/hooks/companyData";
import { employeeStatuses } from "../../../../../helpers/components/employees";
import useAccountingData from "../../../../../helpers/hooks/accountingData";

const EmployeeRowCommuteAvatars = ({
  status,
  currentEndpoints = [],
  electricRenewablesPercentage,
}) => {
  const { palette } = useTheme();

  const officeTypes = useAllOfficeTypes("sm");

  if (status === "terminated" || !currentEndpoints) {
    return <></>;
  }

  const findOfficeObj = (type) =>
    officeTypes.find((categoryObj) => categoryObj.type === type);

  return (
    <Grid container spacing={1} wrap="nowrap">
      {status !== "confirmed" && !!currentEndpoints.length && (
        <Grid item>
          <Tooltip title="This is only this employee's most recent schedule and not their current schedule">
            <Avatar
              style={{
                backgroundColor: palette.error.main,
                height: "36px",
                width: "36px",
              }}
            >
              <ErrorOutlineIcon />
            </Avatar>
          </Tooltip>
        </Grid>
      )}
      {[...currentEndpoints]
        .sort((a, b) => b.daysPerWk - a.daysPerWk)
        .map(({ type, name, daysPerWk }, idx, arr) => (
          <Grid item key={`endpoint-display-avatar-${idx}`}>
            <Tooltip
              title={`${name || "Home"}- ${daysPerWk} days per week. ${
                electricRenewablesPercentage && type === "homeOffice"
                  ? ` ${electricRenewablesPercentage}% of this home office is covered by renewable energy source`
                  : ""
              }  `}
            >
              <Avatar
                style={{
                  backgroundColor:
                    electricRenewablesPercentage && type === "homeOffice"
                      ? palette.secondary.main
                      : findOfficeObj(type)?.color,
                  height: "36px",
                  width: "36px",
                }}
              >
                {findOfficeObj(type)?.icon}
              </Avatar>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const EmployeeRowVehicleChips = ({ vehicles = [] }) => {
  const { palette } = useTheme();

  const vehicleTypes = useAllVehicleTypes();
  const { icon: vehicleIcon } = vehicleTypes.find(
    ({ type }) => type === "personal"
  );

  return (
    <>
      {!!vehicles.length && (
        <Grid container spacing={1}>
          {vehicles
            .filter(({ archived }) => !archived)
            .map(({ name, description, make, model, year }, idx) => (
              <Grid item key={`employee-row-vehicle-chip-${idx}`}>
                <Tooltip title={`${year} ${make} ${model}`}>
                  <span>
                    <Chip
                      style={{
                        backgroundColor: palette.vehicles.main,
                        color: "white",
                      }}
                      label={ucFirstLetters(truncateText(name || description))}
                      icon={
                        <FontAwesomeIcon
                          icon={vehicleIcon}
                          style={{ color: "white" }}
                        />
                      }
                      size="small"
                    />
                  </span>
                </Tooltip>
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

const EmployeeRowMonthlyVolume = ({
  status,
  totalMonthlyVolume,
  mostRecentEmissionsVolume,
  defaultTotalEmissions,
}) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const buildBaseString = (value) =>
    `${formatDecimal(value)} ${displayUnitLabel}`;

  if (status === "confirmed") {
    return <>{buildBaseString(totalMonthlyVolume)}</>;
  }

  if (status === "unconfirmed") {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>{buildBaseString(defaultTotalEmissions)}</Grid>
        <Grid item>
          <Tooltip title="Emissions for unconfirmed employees are estimated from your company's industry, size and location.">
            <HelpOutlineIcon />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  const tooltip =
    status === "onLeave"
      ? "Employees on leave don't count towards your current balance but this is how much they were emitting when they last worked"
      : "We're estimating this employee's emissions based on the recent commuting schedule since their profile is currently incomplete";

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        {buildBaseString(mostRecentEmissionsVolume || totalMonthlyVolume)}
      </Grid>
      <Grid item>
        <Tooltip title={tooltip}>
          <HelpOutlineIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const EmployeeRow = ({ onClick, employee }) => {
  const {
    status,
    name,
    email,
    vehicles = [],
    commuteSchedules,
    mostRecentEmissionsVolume,
    totalMonthlyVolume,
    defaultTotalEmissions,
    endDate,
  } = employee;

  const { palette } = useTheme();

  const [isSurveySent, setIsSurveySent] = useState(false);

  const { icon, tooltip, color } = employeeStatuses[status] || {};

  const safelyDestructureCommuteSchedules = () => {
    if (!Array.isArray(commuteSchedules)) {
      return { home: {}, currentEndpoints: [] };
    }
    const [{ commuteEndpoints: currentEndpoints = [], home = {} }] =
      commuteSchedules || [{}];

    return { currentEndpoints, home };
  };

  const { currentEndpoints, home } = safelyDestructureCommuteSchedules();

  const {
    isElectricRenewablesPercentageGiven = false,
    electricRenewablesPercentage = 0,
  } = home;

  const buildCellsByStatus = () => {
    if (status === "terminated") {
      return (
        <TableCell colSpan={3}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align="center"
          >{`This employee hasn't contributed to your emissions since their last day on ${formatDate(
            endDate
          )}`}</Typography>
        </TableCell>
      );
    }

    if (status === "unconfirmed") {
      return (
        <>
          <TableCell colSpan={2}>
            <Box
              p={1}
              style={{
                backgroundColor: palette.error.main,
                borderRadius: "5px",
              }}
            >
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mergeDarkTheme}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    wrap="nowrap"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      container
                      spacing={1}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <WarningIcon style={{ color: "white" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" color="textPrimary">
                          This employee still hasn't filled out their employee
                          survey
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {!isSurveySent ? (
                        <Button
                          size="small"
                          variant="outlined"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={(e) => {
                            sendEmployeeSurvey({ employee });
                            setIsSurveySent(true);
                            return e.stopPropagation();
                          }}
                        >
                          Resend Survey
                        </Button>
                      ) : (
                        <>
                          <Grid item style={{ marginRight: "16px" }}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="2x"
                              style={{ color: palette.secondary.main }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" color="textPrimary">
                              Survey has been sent!
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </StyledEngineProvider>
            </Box>
          </TableCell>
          <TableCell>
            <EmployeeRowMonthlyVolume
              status={status}
              totalMonthlyVolume={totalMonthlyVolume}
              mostRecentEmissionsVolume={mostRecentEmissionsVolume}
              defaultTotalEmissions={defaultTotalEmissions}
            />
          </TableCell>
        </>
      );
    }

    return (
      <>
        <TableCell>
          <EmployeeRowVehicleChips vehicles={vehicles} />
        </TableCell>
        <TableCell>
          <EmployeeRowCommuteAvatars
            status={status}
            currentEndpoints={currentEndpoints}
            electricRenewablesPercentage={electricRenewablesPercentage}
            isElectricRenewablesPercentageGiven={
              isElectricRenewablesPercentageGiven
            }
          />
        </TableCell>
        <TableCell>
          <EmployeeRowMonthlyVolume
            status={status}
            totalMonthlyVolume={totalMonthlyVolume}
            mostRecentEmissionsVolume={mostRecentEmissionsVolume}
            defaultTotalEmissions={defaultTotalEmissions}
          />
        </TableCell>
      </>
    );
  };

  return (
    <TableRow hover style={{ cursor: "pointer" }} onClick={onClick}>
      <TableCell>
        <Tooltip title={tooltip || ""}>
          <div>
            <FontAwesomeIcon icon={icon} size="2x" style={{ color }} />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <div>{email}</div>
        <Typography variant="caption" color="textSecondary">
          {name}
        </Typography>
      </TableCell>
      {buildCellsByStatus()}
    </TableRow>
  );
};

const editMostRecentSchedule = (commuteSchedules, date) =>
  commuteSchedules.map((schedule, idx) =>
    idx === 0
      ? { endDate: dayjs(date).subtract(1, "day").toDate(), ...schedule }
      : schedule
  );

const useConfirmedEmployeeDetails = (employee, setSelectedEmployee) => {
  const { startDate, id, status, commuteSchedules = [{}], links } = employee;

  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [{ startDate: companyStartDate, id: companyId }] = useAccountData();
  const [{ mostRecentAccountingDate }] = useAccountingData();

  const [modifyEmployee, setModifyEmployee] = useState(employee);
  const [saveLoading, setSaveLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [leaveAnchorEl, setLeaveAnchorEl] = useState(null);

  const { email: modifyEmail, startDate: modifyStartDate } = modifyEmployee;

  const editEmployee = (field) => (value) =>
    editObjectData(setModifyEmployee, field, value);

  const onEmployeeSave = async () => {
    setSaveLoading(true);
    const { startDate } = modifyEmployee;
    const employeeUpdateObj = {
      startDate: dayjs(startDate).toDate(),
    };
    await updateCollectionDoc("employees", id, employeeUpdateObj);
    setSelectedEmployee(modifyEmployee);
    return setSaveLoading(false);
  };

  const takeEmployeeOffLeave = (date) => {
    const { endDate, ...otherCommuteScheduleProps } = commuteSchedules[1] || {};
    const newCurrentCommuteSchedule = {
      ...otherCommuteScheduleProps,
      startDate: dayjs(date).toDate(),
    };
    const newCommuteSchedules = [
      newCurrentCommuteSchedule,
      ...editMostRecentSchedule(commuteSchedules, date),
    ];

    updateCollectionDoc("employees", id, {
      commuteSchedules: newCommuteSchedules,
      links: buildNewEmployeeStatusLinks(employee, "incomplete"),
    });
    return newCommuteSchedules;
  };

  const contentDatePopperProps =
    status === "onLeave"
      ? {
          title: "When did this employee's leave end?",
          buttonText: "Take Employee off Leave",
          onClick: (date) => {
            const newCommuteSchedules = takeEmployeeOffLeave(date);
            editObjectData(
              setSelectedEmployee,
              "links",
              buildNewEmployeeStatusLinks(employee, "incomplete")
            );
            editObjectData(
              setSelectedEmployee,
              "commuteSchedules",
              newCommuteSchedules
            );
            editObjectData(setSelectedEmployee, "status", "incomplete");
            setSelectedEmployee(null);
            return setAnchorEl(null);
          },
        }
      : {
          title: "When did this employee's leave start?",
          buttonText: "Set Employee on Leave",
          onClick: (date) => {
            const newCommuteSchedules = [
              { startDate: date, commuteEndpoints: [], leave: true },
              ...editMostRecentSchedule(commuteSchedules, date),
            ];
            updateCollectionDoc("employees", id, {
              commuteSchedules: newCommuteSchedules,
              links: buildNewEmployeeStatusLinks(employee, "onLeave"),
            });
            editObjectData(
              setSelectedEmployee,
              "links",
              buildNewEmployeeStatusLinks(employee, "onLeave")
            );
            editObjectData(
              setSelectedEmployee,
              "commuteSchedules",
              newCommuteSchedules
            );
            editObjectData(setSelectedEmployee, "status", "onLeave");
            setSelectedEmployee(null);
            return setAnchorEl(null);
          },
        };

  const terminateEmployee = (date) => {
    const { id: companyId } = getAccountCollectionAndId();

    const updatedLinks = links.map((link) =>
      link.id !== companyId
        ? link
        : {
            ...link,
            status: "inactive",
            employeeStatus: "terminated",
            endDate: date,
          }
    );

    updateCollectionDoc("employees", id, {
      commuteSchedules: [...editMostRecentSchedule(commuteSchedules, date)],
      links: updatedLinks,
      endDate: date,
    });
    return setSelectedEmployee(null);
  };

  const employeeSurveyUrl = `http://${window.location.host}/employee-survey/${companyId}/new-employee/${id}`;

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            <SendEmployeeSurveyButton employee={employee} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Tooltip title="Copy Survey Link">
                  <IconButton
                    onClick={() =>
                      navigator.clipboard.writeText(employeeSurveyUrl)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Go to employee survey">
                  <Link href={employeeSurveyUrl}>
                    <IconButton>
                      <OpenInNewIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Tooltip title="Please contact support if you need to change this employee's email address">
            <span>
              <TextField
                value={modifyEmail}
                setValue={editEmployee("email")}
                label="Email address"
                disabled
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item sm={12}>
          <DatePicker
            disabled={
              mostRecentAccountingDate &&
              dayjs(modifyStartDate).isBefore(dayjs(mostRecentAccountingDate))
            }
            date={modifyStartDate || companyStartDate}
            editDate={editEmployee("startDate")}
            label="When did this employee start?"
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                if (anchorEl) {
                  setAnchorEl(null);
                }
                return setLeaveAnchorEl(leaveAnchorEl ? null : e.currentTarget);
              }}
            >
              {status === "onLeave"
                ? "Take Employee Off Leave"
                : "Place Employee on Leave"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DatePopper
        {...contentDatePopperProps}
        open={!!leaveAnchorEl}
        anchorEl={leaveAnchorEl}
        setAnchorEl={setLeaveAnchorEl}
        selectedScheduleProps={{ prevSchedule: commuteSchedules[0] }}
        employee={employee}
      />
    </>
  );

  const footer = (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tooltip title="Remove employee">
            <span>
              <IconButton
                onClick={(e) => {
                  if (leaveAnchorEl) {
                    setLeaveAnchorEl(null);
                  }
                  return setAnchorEl(anchorEl ? null : e.currentTarget);
                }}
                size="large"
              >
                <DeleteForever color="error" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <LoadingButton
            isLoading={saveLoading}
            label="Save Changes"
            color="primary"
            disabled={dayjs(startDate).isSame(modifyStartDate, "day")}
            onClick={onEmployeeSave}
          />
        </Grid>
      </Grid>
      <DatePopper
        title="When was this employee's last day?"
        buttonText="Remove Employee"
        onClick={terminateEmployee}
        employee={employee}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open
      />
    </>
  );

  return { content, footer };
};

const SurveyEmployeeDetailView = ({ employee, setSelectedEmployee }) => {
  const { id: companyId } = getAccountCollectionAndId();

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [sendSurveyLoading, setSendSurveyLoading] = useState(false);

  const { name, status, endDate, id, links, currentLinkedIds } = employee;
  const { icon: statusIcon, color: statusColor } = employeeStatuses[status];

  const [deleteEmployeeLoading, setDeleteEmployeeLoading] = useState(false);

  const onDeleteEmployee = () => {
    setDeleteEmployeeLoading(true);

    const updatedLinks = links.map((link) =>
      link.id === companyId
        ? {
            ...link,
            employeeStatus: "deleted",
            endDate: new Date(),
            status: "inactive",
          }
        : link
    );
    const updatedCurrentLinkedIds = currentLinkedIds.filter(
      (id) => id !== companyId
    );

    return updateCollectionDoc("employees", id, {
      links: updatedLinks,
      currentLinkedIds: updatedCurrentLinkedIds,
    }).then(() => setSelectedEmployee(null));
  };

  const statusProps = {
    unconfirmed: {
      title: `We're using default values for ${name}'s emissions because they haven't completed their employee survey. Would you like to resend their survey invite?`,
      buttons: [
        <LoadingButton
          isLoading={sendSurveyLoading}
          color="primary"
          label="Resend Employee Survey"
          onClick={() => sendEmployeeSurvey({ employee, setSendSurveyLoading })}
        />,
        deleteEmployeeLoading ? (
          <CircularProgress />
        ) : (
          <Tooltip title="Delete this employee">
            <IconButton onClick={onDeleteEmployee} size="large">
              <DeleteForever />
            </IconButton>
          </Tooltip>
        ),
      ],
    },
    terminated: {
      title: `${name}'s last day at your company was on ${formatDate(
        endDate
      )}. Any emissions displayed for ${name} reflect their emissions status on their last day.`,
    },
  };

  const { title, buttons } = statusProps[status];

  return (
    <Grid container direction="column" spacing={4} alignItems="center">
      <Grid item>
        <FontAwesomeIcon
          icon={statusIcon}
          size="10x"
          style={{ color: statusColor }}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" align="center">
          {title}
        </Typography>
      </Grid>
      {buttons && (
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {buttons.map((button, idx) => (
            <Grid item key={`unconfirmed-action-button-${idx}`}>
              {button}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const VehiclesDetailsFooter = ({ setDisplayVehicles }) => {
  const addAnotherVehicle = () =>
    addDataRow(setDisplayVehicles, {
      id: generateSecureId(),
      isNewAndUnsaved: true,
    });

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => addAnotherVehicle()}
        >
          Add Another Vehicle
        </Button>
      </Grid>
    </Grid>
  );
};

const EmployeeDetailsSlider = ({
  emissions,
  setSelectedTransaction,
  employee,
  setSelectedEmployee,
}) => {
  const { id, status, vehicles = [], name } = employee;

  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [companyData] = useAccountData();

  const onNameSave = (name) => updateCollectionDoc("employees", id, { name });

  const {
    content: confirmedEmployeeDetailsContent,
    footer: confirmedEmployeeDetailsFooter,
  } = useConfirmedEmployeeDetails(employee, setSelectedEmployee);

  const [displayVehicles, setDisplayVehicles] = useState(
    vehicles.filter(({ archived }) => !archived)
  );

  const buildDetailsWarning = () => {
    if (status === "incomplete") {
      return {
        text: "This employee's commute schedules are incomplete. Please update their commute endpoints or send them a new employee survey",
        action: <SendEmployeeSurveyButton employee={employee} />,
      };
    }

    if (status === "onLeave") {
      return {
        text: "This employee is currently on leave. Don't forget to take them off leave when they're done.",
      };
    }

    return;
  };

  const views =
    status === "terminated" || status === "unconfirmed"
      ? [
          {
            content: (
              <SurveyEmployeeDetailView
                employee={employee}
                setSelectedEmployee={setSelectedEmployee}
              />
            ),
          },
        ]
      : [
          {
            label: "Emissions",
            value: "emissions",
            content: (
              <EmissionsDetailsBlock
                type="employees"
                emissions={emissions}
                setSelectedTransaction={setSelectedTransaction}
                closeSelectedObjectSlider={() => setSelectedEmployee(null)}
                warning={buildDetailsWarning()}
                name={name}
              />
            ),
          },
          {
            label: "Details",
            value: "details",
            content: confirmedEmployeeDetailsContent,
            footer: confirmedEmployeeDetailsFooter,
          },
          {
            label: "Vehicles",
            value: "vehicles",
            content: (
              <EditableVehiclesCards
                vehicles={displayVehicles}
                setVehicles={setDisplayVehicles}
                employee={employee}
                companyData={companyData}
              />
            ),
            footer: (
              <VehiclesDetailsFooter setDisplayVehicles={setDisplayVehicles} />
            ),
          },
          {
            label: "Commuting",
            value: "commuting",
            content: (
              <EmployeeCommuteSchedulesView
                employee={employee}
                setSelectedEmployee={setSelectedEmployee}
              />
            ),
          },
        ];

  return (
    <SettingsObjectDetailsSlider
      type="employees"
      settingsObject={employee}
      onNameSave={onNameSave}
      setSelectedObject={setSelectedEmployee}
      views={views}
    />
  );
};

const EmployeesDisplayBlock = ({
  employeeSearchString,
  setEmployeeSearchString,
  setAddEmployees,
  setSelectedEmployee,
  displayEmployees,
  filterPopperProps,
  chips,
  viewLoading,
  employeeNotifications,
}) => {
  const { viewMode } = useContext(PlatformLayoutContext);
  const { isEmployeeLimitReached } = useStarterTierSubscriptionFlags();

  const isNotCompanyViewMode = viewMode !== "company";

  const tableColumns = [
    makeColumnObj("STATUS", "status", true),
    makeColumnObj("EMAIL/NAME", "email", true),
    makeColumnObj("VEHICLES"),
    makeColumnObj("CURRENT WORKSPACES"),
    makeColumnObj("MONTHLY EMISSIONS", "totalMonthlyVolume", true),
  ];

  const setSelectedEmployeeFromGraphs = (id) =>
    setSelectedEmployee(
      displayEmployees.find((employee) => employee.id === id)
    );

  return (
    <>
      <DashboardViewLayout
        viewLoading={viewLoading}
        alerts={employeeNotifications}
        type="employees"
        title="Employees"
        filterPopperProps={filterPopperProps}
        chips={chips}
        primaryAction={
          <Tooltip
            title={
              isEmployeeLimitReached
                ? `You have reached the ${STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES} employee limit. Upgrade your subscription to add more employees`
                : "Adding new employees is disabled in non-company view modes"
            }
            disableHoverListener={
              !isNotCompanyViewMode && !isEmployeeLimitReached
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddEmployees(true)}
                disabled={isNotCompanyViewMode || isEmployeeLimitReached}
              >
                Add New Employees
              </Button>
            </span>
          </Tooltip>
        }
        premiumActions={[
          <TextField
            label="Search Employees"
            value={employeeSearchString}
            setValue={setEmployeeSearchString}
          />,
        ]}
        graph={
          <EmployeesGraph
            arrayOfData={[...displayEmployees].sort(
              (a, b) => a.totalMonthlyVolume - b.totalMonthlyVolume
            )}
            setSelectedObject={setSelectedEmployeeFromGraphs}
          />
        }
        table={
          <SortableTable
            columns={tableColumns}
            rows={[...displayEmployees].sort((a, b) => b.severity - a.severity)}
            rowComponent={(employee, idx) => (
              <EmployeeRow
                key={`employee-row-${employee?.id || idx}`}
                onClick={() => setSelectedEmployee(employee)}
                employee={employee}
              />
            )}
          />
        }
      />
    </>
  );
};

//TODO: BUG- check the filtering, it doesn't seem like it's working properly
const EmployeesViewBlock = ({ transactions, viewLoading }) => {
  const { emissionsMonths } = useEmissionsContext();

  const [{ startDate }] = useAccountData();

  const {
    confirmedEmployees,
    employeesLoading,
    addEmployees,
    setAddEmployees,
    employeeNotifications,
    displayEmployeesWithEmissions,
  } = useEmployeesData({ transactions });

  const { filterChips, filterByChips } = useStatusFilterChips(
    displayEmployeesWithEmissions,
    employeeStatuses
  );

  const pageLoading = employeesLoading || viewLoading;

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectedCommutingMethods, setSelectedCommutingMethods] = useState([]);
  const [employeeSearchString, setEmployeeSearchString] = useState("");

  const resetFilters = () => {
    setSelectedWorkspaces([]);
    return setSelectedCommutingMethods([]);
  };

  const buildFilterFunctions = () => {
    const findVehiclesByType = (type, commuteEndpoints = []) => {
      const endpointsWithVehicles = commuteEndpoints.filter(
        (endpoint) => endpoint.vehicles
      );

      const vehiclesFromEndpoints = endpointsWithVehicles.map(
        ({ vehicles }) => vehicles
      );

      const formattedVehicles = vehiclesFromEndpoints.reduce(
        (acc, vehicles) => [...acc, ...vehicles],
        []
      );

      return formattedVehicles.filter((vehicle) => {
        if (type === "car") {
          return (
            vehicle.id !== "carpool" &&
            vehicle.id !== "walkBike" &&
            vehicle.id !== "lightRail" &&
            vehicle.id !== "bus" &&
            vehicle.id !== "regionalRail" &&
            vehicle.id !== "intercityRail"
          );
        }
        return vehicle.id === type;
      });
    };

    const findCommuteVehicles = (type) =>
      confirmedEmployees.map(({ commuteSchedules = [{}] }) => {
        if (!Array.isArray(commuteSchedules)) {
          return [];
        }

        const [{ commuteEndpoints }] = commuteSchedules;
        return findVehiclesByType(type, commuteEndpoints);
      });

    const vehicleCommuteEndpoints = findCommuteVehicles("car");
    const lightRailCommuteEndpoints = findCommuteVehicles("lightRail");
    const regionalRailCommuteEndpoints = findCommuteVehicles("regionalRail");
    const intercityRailCommuteEndpoints = findCommuteVehicles("intercityRail");
    const busCommuteEndpoints = findCommuteVehicles("bus");
    const walkBikeCommuteEndpoints = findCommuteVehicles("walkBike");
    const carpoolCommuteEndpoints = findCommuteVehicles("carpool");

    const typesOfCommuting = [
      { type: vehicleCommuteEndpoints, caption: "Car", slug: "car" },
      {
        type: lightRailCommuteEndpoints,
        caption: "Light Rail/Subway",
        slug: "lightRail",
      },
      {
        type: regionalRailCommuteEndpoints,
        caption: "Regional Rail",
        slug: "regionalRail",
      },
      {
        type: intercityRailCommuteEndpoints,
        caption: "Intercity Rail",
        slug: "intercityRail",
      },
      { type: busCommuteEndpoints, caption: "Bus", slug: "bus" },
      { type: carpoolCommuteEndpoints, caption: "Carpool", slug: "carpool" },
      {
        type: walkBikeCommuteEndpoints,
        caption: "Walking/Biking",
        slug: "walkBike",
      },
    ];

    const findCommutingTypes = () => {
      const commutingOptions = typesOfCommuting.filter(({ type }) => {
        const nonEmptyEndpoints = type?.filter(
          (endpoints) => endpoints?.length
        );

        return nonEmptyEndpoints?.length;
      });

      return commutingOptions.map((commuteType) => ({
        name: commuteType.caption,
      }));
    };

    const findWorkspace = (officeType) =>
      confirmedEmployees.map(({ commuteSchedules = [{}] }) => {
        if (!Array.isArray(commuteSchedules)) {
          return [];
        }

        const [{ commuteEndpoints = [{}] }] = commuteSchedules;
        return commuteEndpoints.filter(({ type }) =>
          officeType === "other"
            ? type !== "companyOffice" && type !== "homeOffice"
            : type === officeType
        );
      });

    const homeOfficeCommuteEndpoints = findWorkspace("homeOffice");
    const companyOfficeCommuteEndpoints = findWorkspace("companyOffice");
    const otherOfficeCommuteEndpoints = findWorkspace("other");

    const typesOfWorkspaces = [
      {
        type: companyOfficeCommuteEndpoints,
        caption: "Company Workspaces",
        slug: "companyOffice",
      },
      {
        type: homeOfficeCommuteEndpoints,
        caption: "Home Office",
        slug: "homeOffice",
      },
      {
        type: otherOfficeCommuteEndpoints,
        caption: "Other Workspaces",
        slug: "otherOffice",
      },
    ];

    const findWorkspaceTypes = () => {
      const workspacesOptions = typesOfWorkspaces.filter(({ type }) => {
        const nonEmptyEndpoints = type.filter((endpoints) => endpoints.length);
        return nonEmptyEndpoints.length;
      });

      return workspacesOptions.map((workspace) => ({
        name: workspace.caption,
      }));
    };

    const findWorkspaceByType = (workspaceSlug, commuteEndpoints) =>
      commuteEndpoints.find((endpoint) => endpoint.type === workspaceSlug);

    const commuteSchedulesFilterFunction =
      (filterVariable, filterFunction, filterVariableList) =>
      ({ commuteSchedules }) => {
        if (!filterVariable.length) {
          return true;
        }

        if (!Array.isArray(commuteSchedules) || !commuteSchedules?.length) {
          return false;
        }

        const [{ commuteEndpoints }] = commuteSchedules;

        const filterVariableSlugs = filterVariable.map(
          ({ name }) =>
            filterVariableList.find((type) => type.caption === name)?.slug
        );

        return filterVariableSlugs.some((methodSlug) =>
          filterFunction(methodSlug, commuteEndpoints)
        );
      };

    const filterByCommuteVehicle = commuteSchedulesFilterFunction(
      selectedCommutingMethods,
      findVehiclesByType,
      typesOfCommuting
    );
    const filterByWorkspace = commuteSchedulesFilterFunction(
      selectedWorkspaces,
      findWorkspaceByType,
      typesOfWorkspaces
    );

    const filterBySearch = ({ email, name }) => {
      if (!employeeSearchString) {
        return true;
      }

      return (
        name.includes(employeeSearchString) ||
        email.includes(employeeSearchString)
      );
    };

    const applyFilters = () =>
      displayEmployeesWithEmissions.filter(
        (employee) =>
          filterByChips(employee) &&
          filterByCommuteVehicle(employee) &&
          filterByWorkspace(employee) &&
          filterBySearch(employee)
      );

    const filterRows = [
      {
        icon: faLaptopHouse,
        title: "Workspaces",
        autocomplete: {
          availableOptions: findWorkspaceTypes(),
          value: selectedWorkspaces,
          setValue: setSelectedWorkspaces,
          label: "Workspace Type",
        },
      },
      {
        icon: faCarBus,
        title: "Commuting Method",
        autocomplete: {
          availableOptions: findCommutingTypes(),
          value: selectedCommutingMethods,
          setValue: setSelectedCommutingMethods,
          label: "Commuting Methods",
        },
      },
    ];

    return { filterRows, applyFilters, displayEmployeesWithEmissions };
  };

  const { filterRows, applyFilters } = buildFilterFunctions();

  const filteredEmployees = applyFilters();

  const filterPopperProps = {
    filterRows,
    resetFilters,
    title: "Filter Employees",
  };

  const buildSelectedEmployeeEmissionsArray = (selectedEmployee) => {
    const taggedEmployeesTransactions = transactions.filter(
      ({ taggedEmployeesIds = [] }) =>
        taggedEmployeesIds.includes(selectedEmployee.id)
    );

    const monthlyCommuteEmissionsTons = buildMonthlyCommuteEmissionsArray({
      employeesSchedules: findEmployeesCommuteSchedules(startDate, [
        selectedEmployee,
      ]),
      emissionsMonths,
    });

    const homeOfficeEmissions =
      findMonthlyUtilitiesEmissionsFromCommuteSchedules({
        employees: [selectedEmployee],
        additionalProps: {
          name: "Home Office",
        },
        emissionsMonths,
        companyStartDate: startDate,
      }).map((emission) => ({ ...emission, subcategory: "home-office" }));

    return [
      ...taggedEmployeesTransactions,
      ...monthlyCommuteEmissionsTons,
      ...homeOfficeEmissions,
    ];
  };

  return (
    <>
      {addEmployees && <AddEmployeesInputs onClose={setAddEmployees} />}
      {selectedEmployee && (
        <EmployeeDetailsSlider
          emissions={buildSelectedEmployeeEmissionsArray(selectedEmployee)}
          setSelectedTransaction={setSelectedTransaction}
          employee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
        />
      )}
      {selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      {pageLoading || displayEmployeesWithEmissions.length ? (
        <EmployeesDisplayBlock
          viewLoading={pageLoading}
          employeeNotifications={employeeNotifications}
          employeeSearchString={employeeSearchString}
          setEmployeeSearchString={setEmployeeSearchString}
          displayEmployees={filteredEmployees}
          setAddEmployees={setAddEmployees}
          setSelectedEmployee={setSelectedEmployee}
          filterPopperProps={filterPopperProps}
          chips={filterChips}
        />
      ) : (
        <MissingEmployees setAddEmployees={setAddEmployees} />
      )}
    </>
  );
};

const EmployeesView = (props) => (
  <ErrorBoundary>
    <EmployeesViewBlock {...props} />
  </ErrorBoundary>
);
export default EmployeesView;
