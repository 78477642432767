import React from "react";

import { PdlCompanySelect as PdlCompanySelectModule } from "@aclymatepackages/modules";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const PdlCompanySelect = (props) => {
  const companyMatchingFunction = async (inputValue) =>
    await fetchOurApi({
      path: `/companies/query-matching-companies`,
      method: "POST",
      data: { field: "name", value: inputValue },
      callback: ({ matchingCompanies }) => matchingCompanies,
    });

  return (
    <PdlCompanySelectModule
      matchingFunction={companyMatchingFunction}
      {...props}
    />
  );
};
export default PdlCompanySelect;
