import React, { useState } from "react";

import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faChartArea } from "@fortawesome/pro-regular-svg-icons";

import { EmissionsPieChart } from "@aclymatepackages/modules";

import DetailsActionAlert from "../../atoms/notifications/DetailsActionAlert";
import EmissionsDetailsAccordionBlock from "../../layouts/EmissionsDetailsAccordionBlock";
import EmissionsDetailsTable from "../../modules/tables/EmissionsDetailsTable";

import EmissionsChart from "../../modules/charts/EmissionsChart";

import { useAccountData } from "../../../helpers/firebase";

const EmissionsGraphAndPieChart = ({
  type,
  emissions,
  showCharts,
  onChange,
}) => {
  const theme = useTheme();
  const [{ startDate }] = useAccountData();

  const [isPieChartToggled, setIsPieChartToggled] = useState(false);

  const iconButtons = [
    { isPieChart: false, icon: faChartArea, tooltip: "Area Graph View" },
    { isPieChart: true, icon: faChartPie, tooltip: "Pie Chart View" },
  ];

  const styleObj = isPieChartToggled
    ? {
        color: theme.palette.primary.main,
      }
    : {
        color: "#D3D3D3",
      };

  return (
    <EmissionsDetailsAccordionBlock
      expanded={showCharts}
      onChange={onChange}
      type={type}
      title="Emissions Charts"
      blockAction={iconButtons.map(({ isPieChart, icon, tooltip }, idx) => (
        <Tooltip title={tooltip} key={`details-chart-toggle-${idx}`}>
          <span>
            <IconButton onClick={() => setIsPieChartToggled(isPieChart)}>
              <FontAwesomeIcon icon={icon} style={{ styleObj }} />
            </IconButton>
          </span>
        </Tooltip>
      ))}
      details={
        <>
          {!isPieChartToggled ? (
            <EmissionsChart
              dataArray={emissions}
              type="bar"
              startDate={startDate}
            />
          ) : (
            <EmissionsPieChart dataArray={emissions} />
          )}
        </>
      }
    />
  );
};

const EmissionsDetailsBlock = ({
  closeSelectedObjectSlider,
  setSelectedTransaction,
  emissions,
  type,
  warning,
  name,
  chartEmissions,
}) => {
  const removedZeroTonsFormattedEmissions = emissions.filter(
    ({ tonsCo2e, electricRenewablesPercentage }) =>
      tonsCo2e || electricRenewablesPercentage === 100
  );

  const [showCharts, setShowCharts] = useState(true);
  const onAccordionClick = () => setShowCharts((currentState) => !currentState);

  return (
    <Grid container item spacing={2} direction="column">
      {warning && <DetailsActionAlert {...warning} />}
      <Grid item>
        <EmissionsGraphAndPieChart
          emissions={chartEmissions || removedZeroTonsFormattedEmissions}
          type={type}
          showCharts={showCharts}
          onChange={onAccordionClick}
        />
      </Grid>
      <Grid item>
        <EmissionsDetailsTable
          emissions={removedZeroTonsFormattedEmissions}
          setSelectedTransaction={setSelectedTransaction}
          closeSelectedObjectSlider={closeSelectedObjectSlider}
          type={type}
          showCharts={showCharts}
          onChange={onAccordionClick}
          name={name}
        />
      </Grid>
    </Grid>
  );
};
export default EmissionsDetailsBlock;
