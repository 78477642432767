import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router";

import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  ThemeProvider,
  Button,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons";

import { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  editObjectData,
  editRowData,
  addDataRow,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import {
  Checkbox,
  TextField,
  Select,
  AnimatedLogo,
} from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { IndustryAutocomplete } from "@aclymatepackages/modules";

import VendorHeader from "./VendorHeader";
import SignInForm from "../account-access/SignInForm";
import AccountCreationInput from "../account-access/AccountCreationInput";
import DollarInput from "../atoms/DollarInput";
import OrDivider from "../atoms/OrDivider";
import FacilityTypeSelect from "../inputs/offices/FacilityTypeSelect";
import GasUnitSelect from "../inputs/offices/GasUnitSelect";
import PlacesAutocomplete from "../inputs/autocomplete/PlacesAutocomplete";

import { useVendorSurveyData } from "../../helpers/components/vendors";
import {
  fetchAddressAndAirport,
  setAddress,
} from "../../helpers/utils/geography";
import {
  fetchOurApi,
  fetchZipCodeEGrid,
  fetchCarbonFromGallons,
  fetchFuelBillCarbon,
  newAuthUser,
  deleteOauthUser,
} from "../../helpers/utils/apiCalls";
import { useAuth } from "../../helpers/firebase";
import { isObjectEmpty } from "../../helpers/otherHelpers";
import {
  onSignInWithEmailAndPassword,
  isEmailPersonal,
} from "../../helpers/components/accountAccess";

const SURVEY_YEAR = dayjs().year() - 1;

const transactionsQuestions = [
  {
    title: "Business Travel",
    naics: 561599,
    scopeThreeCategory: 6,
    description:
      "Text about why we care about business expenses and whey it's important to track them bla bla bla",
    questions: [
      { label: "Train tickets", naics: 485112 },
      { label: "Flight Tickets", naics: 481111 },
      { label: "Bus Tickets", naics: 485210 },
      { label: "Car Rental", naics: 532111 },
      {
        label: "Hotel + Restaurants + Catering",
        naics: 721110,
      },
    ],
  },
  {
    title: "Transportation/Freight",
    naics: 488999,
    scopeThreeCategory: 4,
    description:
      "Text about why we care about travel/freight expenses and why it's important to track them and bla bla bla",
    questions: [
      { label: "Road Freight", naics: 488490 },
      { label: "Air Freight", naics: 481112 },
      { label: "Sea Freight", naics: 483113 },
      { label: "Rail Freight", naics: 482111 },
      {
        label: "Other Transport Services",
        helperText: "Warehousing, etc...",
        naics: 493190,
      },
    ],
  },
  {
    title: "Capital Goods",
    naics: 423830,
    scopeThreeCategory: 2,
    description:
      "Why we care about materials inventory and why it's important to track it and bla bla bla",
    questions: [
      {
        label: "Furniture",
        helperText: "Chairs, tables, other office interior",
        naics: 423210,
      },
      {
        label: "Paper and packaging",
        helperText: "Cardboard, paper, post-its, etc...",
        naics: 561910,
      },
      {
        label: "Textiles",
        helperText: "Work clothes, fabrics, clothes resold in stores",
        naics: 314999,
      },
      {
        label: "Metal Products",
        helperText: "Steel beams, staplers or metals",
        naics: 326199,
      },
      {
        label: "Chemical and Pharmaceuticals",
        helperText: "Paints, chemicals, medicines",
        naics: 325412,
      },
      {
        label: "Other General Products",
        naics: 488999,
        helperText: "Food products, misc. inventory",
      },
      {
        label: "Book, movies and related services",
        helperText: "Publishing, printing, and reproduction of recorded media",
        naics: 451211,
      },
      {
        label: "Phones, televisions and comms equipment",
        helperText: "Smartphones, monitors, radios",
        naics: 517911,
      },
      {
        label: "Computers and office machinery",
        helperText: "Printers, computers or label makers",
        naics: 811212,
      },
      {
        label: "Purchased Vehicles",
        helperText: "Motor vehicles, trailers, boats, etc...",
        naics: 336211,
      },
      {
        label: "Other Machinery Tools and Equipment",
        helperText: "Drills, large machinery, heaters or processors",
        naics: 333249,
      },
    ],
  },
  {
    title: "Services",
    naics: 561499,
    scopeThreeCategory: 1,
    description:
      "Why do we care about your services expenses and why we need to track them bla bla bla",
    questions: [
      {
        label: "Legal, Accounting and Management Consulting",
        helperText: "Consultants, lawyers, accountants",
        naics: 541199,
      },
      {
        label: "Software, hosting, computer programming, etc...",
        helperText:
          "IT consultants, outsourced programming, SaaS subscriptions, data hosting services, software, digital advertising",
        naics: 511210,
      },
      {
        label: "Insurance and Pension Funding",
        helperText: "Insurance Costs",
        naics: 524292,
      },
      {
        label: "Financial Intermediation",
        helperText: "Banking fees and charges",
        naics: 523999,
      },
      {
        label: "Construction and Maintenance",
        naics: 236220,
      },
    ],
  },
];

const useOnVendorSubmit = () => {
  const [user] = useAuth();
  const { uid: userId } = user || {};
  const { invitationId, companyId } = useParams();

  return async (section, data) =>
    await fetchOurApi({
      accountId: companyId,
      user,
      path: "/companies/post-vendor-survey-data",
      method: "POST",
      data: {
        companyId,
        userId,
        invitationId,
        section,
        data,
        surveyYear: SURVEY_YEAR,
      },
    });
};

const useOnSurveyLifecycleUpdate = ({ currentSurvey, field }) => {
  const onVendorSubmit = useOnVendorSubmit();

  return () =>
    onVendorSubmit("information", {
      "vendorData.surveys": [{ ...currentSurvey, [field]: new Date() }], //TODO: this needs to be updated for when they retake the survey
    });
};

const DateRangeText = () => (
  <Typography
    variant="subtitle2"
    color="textSecondary"
    style={{ fontStyle: "italic", fontWeight: 600 }}
  >
    From 1/1/{SURVEY_YEAR} to 12/31/{SURVEY_YEAR}
  </Typography>
);

const FormQuestionLayout = ({
  onSave,
  field,
  value,
  section,
  saveDisabled,
  onNextStep,
  onPrevStep,
  children,
}) => {
  const onVendorSubmit = useOnVendorSubmit();

  const onSubmit = () => {
    onNextStep();

    if (onSave) {
      return onSave();
    }
    return onVendorSubmit(section, { [field]: value });
  };

  return (
    <Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box py={2}>{children}</Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button disabled={!onPrevStep} onClick={onPrevStep}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={saveDisabled}
            onClick={onSubmit}
            variant="contained"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const Login = ({ onNextStep, setErrorMsg }) => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  return (
    <SignInForm
      userEmail={userEmail}
      setUserEmail={setUserEmail}
      password={password}
      setPassword={setPassword}
      loginLoading={loginLoading}
      setLoginLoading={setLoginLoading}
      setAccountAccessError={setErrorMsg}
      onLogin={onNextStep}
      hideCta
    />
  );
};

const CreateAccount = ({ onNextStep, existingUser, setIsLoading }) => {
  const { invitationId, companyId } = useParams();

  const [showLogin, setShowLogin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [userLoading, setUserLoading] = useState(false);

  const { name: displayName } = existingUser || {};

  const confirmUser = async (user) =>
    await fetchOurApi({
      path: `/users/confirm`,
      method: "POST",
      data: {
        companyId,
        inviteId: invitationId,
        ...user,
      },
      callback: () => {
        onNextStep();
        return setIsLoading(false);
      },
    });

  const completePasswordEmailAccountCreation = async (inputData) => {
    setIsLoading(true);
    setUserLoading(true);

    const userData = {
      ...existingUser,
      ...inputData,
      displayName,
    };

    const { error, uid } = await newAuthUser(userData);
    if (error) {
      setErrorMsg("This user already exists. Please try logging in instead.");
      return setUserLoading(false);
    }

    await onSignInWithEmailAndPassword(userData);
    return await confirmUser({ ...userData, uid });
  };

  const completeOauthAccountCreation = async (user) => {
    const { email, displayName } = user;

    if (isEmailPersonal(email)) {
      setErrorMsg("Please use a work email to sign up for Aclymate");
      return deleteOauthUser(email);
    }

    setIsLoading(true);
    setUserLoading(true);

    return await confirmUser({ ...user, name: displayName });
  };

  return (
    <Container maxWidth="sm">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h3">
            {showLogin
              ? "Welcome back! Log back in to complete your company's survey."
              : "Create your free account to get started calculating your company’s footprint!"}
          </Typography>
        </Grid>
        {showLogin ? (
          <Login onNextStep={onNextStep} />
        ) : (
          <AccountCreationInput
            initialUserData={existingUser}
            setErrorMsg={setErrorMsg}
            userLoading={userLoading}
            isCompanyAccountCreation
            completePasswordEmailAccountCreation={
              completePasswordEmailAccountCreation
            }
            completeOauthAccountCreation={completeOauthAccountCreation}
          />
        )}
        {!!errorMsg && (
          <Grid item>
            <Typography variant="subtitle2" color="error">
              {errorMsg}
            </Typography>
          </Grid>
        )}
        {!showLogin && (
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Already have an account?{" "}
              <span
                onClick={() => setShowLogin(true)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Login instead.
              </span>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

const YearConfirmation = ({ onNextStep, currentSurvey }) => {
  const onSurveyLifecycleUpdate = useOnSurveyLifecycleUpdate({
    currentSurvey,
    field: "dateStarted",
  });

  const [isChecked, setIsChecked] = useState(false);

  const onSave = () => {
    onSurveyLifecycleUpdate();
    return onNextStep();
  };

  return (
    <FormQuestionLayout onNextStep={onSave} saveDisabled={!isChecked}>
      <Grid container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h3" paragraph>
            Note: This survey will be asking you to provide information for the
            previous calendar year:
          </Typography>
          <DateRangeText />
        </Grid>
        <Grid item>
          <Checkbox
            label="I Understand"
            value={isChecked}
            editValue={setIsChecked}
          />
        </Grid>
      </Grid>
    </FormQuestionLayout>
  );
};

const FormDateTitleLayout = ({ children, title, ...otherProps }) => (
  <FormQuestionLayout {...otherProps}>
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <DateRangeText />
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  </FormQuestionLayout>
);

const YesNoCheckboxes = ({ value, setValue }) => (
  <Grid container spacing={1} direction="column">
    <Grid item>
      <Checkbox label="Yes" value={!!value} editValue={() => setValue(true)} />
    </Grid>
    <Grid item>
      <Checkbox
        label="No"
        value={value === false}
        editValue={() => setValue(false)}
      />
    </Grid>
  </Grid>
);

const EmployeeCount = ({ employeeCount, setEmployeeCount }) => (
  <Grid container spacing={4} direction="column">
    <Grid item>
      <TextField value={employeeCount} setValue={setEmployeeCount} />
    </Grid>
    {employeeCount >= 50 && (
      <Grid item>
        <Typography variant="subtitle1" color="secondary">
          For companies with 50+ employees, we recommend our Turn Key service to
          speed up your data entry. Learn more
        </Typography>
      </Grid>
    )}
  </Grid>
);

const FacilitiesInputBlock = ({
  facilities,
  setFacilities,
  onNextStep,
  onPrevStep,
  onSave,
}) => {
  const [selectedOfficeIdx, setSelectedOfficeIdx] = useState(0);

  const {
    name,
    address,
    sqFootage,
    facilityType,
    electricKwh,
    gasVolume,
    gasVolumeUnit,
  } = facilities[selectedOfficeIdx] || {};

  const editFacility = (field) => (value) =>
    editRowData(selectedOfficeIdx, setFacilities)(field, value);

  const onNewFacility = () => {
    setSelectedOfficeIdx(facilities.length);
    return addDataRow(setFacilities, {});
  };

  const onRemoveFacility = () => {
    removeDataRow(selectedOfficeIdx, setFacilities)();
    if (!!selectedOfficeIdx) {
      return setSelectedOfficeIdx((currentIndex) => currentIndex - 1);
    }

    return;
  };

  const areAllFacilitiesComplete = facilities.reduce(
    (acc, { name, address, sqFootage, facilityType }) =>
      acc &&
      name &&
      address &&
      sqFootage &&
      numbersRegExpTest(sqFootage) &&
      facilityType,
    true
  );

  return (
    <FormDateTitleLayout
      title="Provide information on your company's facilities"
      saveDisabled={!areAllFacilitiesComplete}
      onPrevStep={onPrevStep}
      onNextStep={onNextStep}
      onSave={onSave}
    >
      <Grid container spacing={4} direction="column">
        {facilities.length > 1 && (
          <Grid item>
            <Select
              options={facilities.map(({ name }, idx) => ({
                label: name || `Facility #${idx + 1}`,
                value: idx,
              }))}
              value={selectedOfficeIdx}
              editValue={setSelectedOfficeIdx}
            />
          </Grid>
        )}
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <TextField
              label="Facility Name"
              value={name}
              setValue={editFacility("name")}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {name || `Facility #${selectedOfficeIdx + 1}`} Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PlacesAutocomplete
                  label="Address"
                  place={address}
                  editPlace={setAddress(editFacility("address"))}
                  size="small"
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Size (sq. footage)"
                  value={sqFootage}
                  setValue={editFacility("sqFootage")}
                />
              </Grid>
              <Grid item xs={7}>
                <FacilityTypeSelect
                  facilityType={facilityType}
                  editFacilityType={editFacility("facilityType")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="textSecondary">
              Add more details (optional)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Total Electric Kwh"
                  value={electricKwh}
                  setValue={editFacility("electricKwh")}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Enter a volume of gas"
                  value={gasVolume}
                  setValue={editFacility("gasVolume")}
                />
              </Grid>
              <Grid item xs={4}>
                <GasUnitSelect
                  gasUnit={gasVolumeUnit}
                  editGasUnit={editFacility("gasVolumeUnit")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justifyContent="center">
          {facilities.length > 1 && (
            <Grid item>
              <Button onClick={onRemoveFacility} color="inherit">
                Remove This Facility
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              onClick={onNewFacility}
              color="secondary"
            >
              Add another facility
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FormDateTitleLayout>
  );
};

const VehicleFuelQuestion = ({
  onNextStep,
  onPrevStep,
  fuelVolume,
  fuelVolumeUnit = "gallons",
  fuelDollars,
  editVehicleFuelData,
  onSave,
}) => (
  <FormDateTitleLayout
    onNextStep={onNextStep}
    onPrevStep={onPrevStep}
    title="How much fuel did you use for these vehicles?"
    saveDisabled={
      !(
        (fuelVolume && fuelVolumeUnit) ||
        (fuelDollars && numbersRegExpTest(fuelDollars))
      )
    }
    onSave={onSave}
  >
    <Grid container directon="column" spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={9}>
          <TextField
            label="Total fuel for time period"
            value={fuelVolume ? Math.round(fuelVolume) : ""}
            setValue={editVehicleFuelData("fuelVolume")}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            size="small"
            options={[
              {
                label: "Gallons",
                value: "gallons",
              },
              { label: "Liters", value: "liters" },
            ]}
            value={fuelVolumeUnit}
            editValue={editVehicleFuelData("fuelVolumeUnit")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <OrDivider />
      </Grid>
      <Grid item xs={12}>
        <DollarInput
          label="Total Amount Spent on Fuel"
          value={fuelDollars}
          setValue={editVehicleFuelData("fuelDollars")}
        />
      </Grid>
    </Grid>
  </FormDateTitleLayout>
);

const TransactionsQuestionsLayout = ({
  onNextStep,
  onPrevStep,
  transaction,
  title,
  questions,
  naics: categoryNaics,
  scopeThreeCategory,
  editTransactionRow,
  isLastQuestion,
  currentSurvey,
}) => {
  const updateSurveyDateComplete = useOnSurveyLifecycleUpdate({
    currentSurvey,
    field: "dateCompleted",
  });
  const onVendorSubmit = useOnVendorSubmit();

  const editTransaction = (field) => (value) =>
    editTransactionRow(field, value);

  const areAllQuestionsAnswered = questions.reduce((acc, { naics }) => {
    const value = transaction[`${naics}`];
    return !!value && numbersRegExpTest(value) && acc;
  }, true);

  const categoryValue = transaction[`${categoryNaics}`];

  const onSave = () => {
    if (isLastQuestion) {
      updateSurveyDateComplete();
    }

    if (categoryValue && !areAllQuestionsAnswered) {
      return onVendorSubmit("transactions", {
        transactions: [
          {
            name: `${SURVEY_YEAR} ${title} Spend`,
            naicsCode: categoryNaics,
            scopeThreeCategory,
            subcategory: "spend-based",
            value: categoryValue,
          },
        ],
      });
    }

    const dbTransactions = questions.map(({ naics, label }) => ({
      name: `${SURVEY_YEAR} ${label} Spend`,
      naicsCode: naics,
      scopeThreeCategory,
      subcategory: "spend-based",
      value: transaction[`${naics}`],
    }));

    return onVendorSubmit("transactions", { transactions: dbTransactions });
  };

  return (
    <FormDateTitleLayout
      title={`What were your company's total ${title.toLowerCase()} expenses?`}
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
      saveDisabled={!areAllQuestionsAnswered && !categoryValue}
      onSave={onSave}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <DollarInput
            label="Enter Total Amount"
            value={categoryValue}
            setValue={editTransaction(categoryNaics)}
          />
        </Grid>
        <Grid item>
          <OrDivider />
        </Grid>
        {questions.map(({ naics, ...otherProps }) => (
          <Grid item key={`question-input-${naics}`}>
            <DollarInput
              value={transaction[naics]}
              setValue={editTransaction(naics)}
              {...otherProps}
            />
          </Grid>
        ))}
      </Grid>
    </FormDateTitleLayout>
  );
};

const StepDescriptionAction = ({ section, title, action, description }) => {
  const theme = useTheme();

  const formSections = [
    { name: "Basic Information", id: "information" },
    { name: "Facilities & Vehicles", id: "facilities" },
    { name: "Expenses", id: "transactions" },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      justifyContent="space-between"
      sx={{ flexGrow: 1 }}
    >
      <Grid item container direction="column" spacing={2}>
        <Grid item style={{ width: "100%" }}>
          <Box
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "50px",
            }}
          >
            <Grid container justifyContent="space-between">
              {formSections.map(({ name, id }, idx) => (
                <Grid item key={`form-section-chip-${idx}`}>
                  <Box
                    py={0.5}
                    px={3}
                    style={{
                      borderRadius: "25px",
                      backgroundColor:
                        id === section
                          ? "rgba(255, 255, 255, 0.8)"
                          : "transparent",
                    }}
                  >
                    <Typography variant="h4" color="textSecondary">
                      {name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <ThemeProvider theme={mergeDarkTheme}>
            <Typography variant="h2" color="textPrimary">
              {title}
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item>
          <ThemeProvider theme={mergeDarkTheme}>
            <Typography variant="subtitle1" color="textPrimary">
              {description}
            </Typography>
          </ThemeProvider>
        </Grid>
      </Grid>
      {action && (
        <Grid item>
          <Paper
            sx={{ display: "flex" }}
            style={{
              backgroundColor: theme.palette.backgroundGray.main,
              borderRadius: theme.spacing(1),
            }}
          >
            <Box
              p={1}
              style={{
                backgroundColor: "white",
                borderRadius: theme.spacing(1),
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FontAwesomeIcon
                icon={faLightbulbOn}
                size="2x"
                style={{ color: theme.palette.secondary.main }}
              />
            </Box>
            <Box p={1}>
              <Typography
                variant="subtitle1"
                style={{ color: theme.palette.secondary.dark }}
              >
                This is an action text to indicate to people that there might be
                an action here.{" "}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

const VendorSurveyError = ({
  message,
  href = "https://support.aclymate.com",
  btnText = "support",
}) => (
  <Box
    style={{ height: "100%" }}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5" align="center">
          {message}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <Button variant="contained" color="primary" href={href}>
            {btnText}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

const VendorSurveyLayout = ({
  surveyLoading,
  form,
  percentCompleted,
  error,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <VendorHeader />
      <Box flexGrow={1} display="flex">
        <Box
          style={{
            width: "50%",
            backgroundColor: theme.palette.secondary.main,
            height: "100%",
            boxSizing: "border-box",
          }}
          boxSizing="border-box"
          p={6}
          display="flex"
          flexDirection="column"
        >
          {!surveyLoading && <StepDescriptionAction {...otherProps} />}
        </Box>
        <Box
          p={6}
          style={{ width: "50%", boxSizing: "border-box" }}
          display="flex"
          flexDirection="column"
        >
          <Box
            flexGrow={0}
            position="relative"
            style={{
              backgroundColor: theme.palette.backgroundGray.main,
              borderRadius: "15px",
              height: "30px",
              width: "100%",
            }}
            mb={4}
          >
            <Box
              position="absolute"
              style={{
                left: 0,
                width: `calc(${percentCompleted * 100}% + 30px)`,
                height: "30px",
                minWidth: "30px",
                borderRadius: "15px",
                backgroundColor: theme.palette.secondary.main,
              }}
            />
          </Box>
          {surveyLoading ? (
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <AnimatedLogo />
            </Box>
          ) : error ? (
            <VendorSurveyError {...error} />
          ) : (
            form
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useCompanyDataSteps = ({
  companyData,
  setCompanyData,
  onNextStep,
  onPrevStep,
}) => {
  const [dbCompanyData = {}, dbCompanyDataLoading] = useVendorSurveyData();
  const { error: dbError } = dbCompanyData;

  const [companyDataLoading, setCompanyDataLoading] = useState(true);
  const [companyDataError, setCompanyDataError] = useState(false);

  useEffect(() => {
    const setLocalCompanyData = () => {
      setCompanyDataLoading(false);
      if (!dbError) {
        return setCompanyData(dbCompanyData);
      }
      return setCompanyDataError(true);
    };

    if (!dbCompanyDataLoading) {
      setLocalCompanyData();
    }
  }, [dbCompanyDataLoading, dbCompanyData, setCompanyData, dbError]);

  const {
    employeeCount,
    isRemote,
    industry,
    revenueYears,
    geography,
    hasNoCompanyVehicles,
  } = companyData;

  const editCompanyData = (field) => (value) =>
    editObjectData(setCompanyData, field, value);

  const [{ revenue }] = revenueYears || [{ revenue: "" }];
  const onRevenueEdit = (revenue) =>
    editCompanyData("revenueYears")([{ year: SURVEY_YEAR, revenue }]);

  const { address } = geography || {};
  const onAddressEdit = async (place) => {
    const { address, airport } = await fetchAddressAndAirport(place);
    const eGrid = await fetchZipCodeEGrid(address);
    return editCompanyData("geography")({
      address,
      defaultAirport: airport,
      eGrid,
    });
  };

  const addressStep = isRemote
    ? [
        {
          field: "geography",
          title: "Account Information",
          description:
            "Some text explaining why we need this company's industry, and why it's important to have this information.",
          formProps: {
            title: "What is your company's business address?",
            saveDisabled: !geography,
          },
          form: (
            <PlacesAutocomplete
              label="Business Address"
              place={address}
              editPlace={onAddressEdit}
            />
          ),
        },
      ]
    : [];

  const companyDataInputSteps = [
    {
      field: "industry",
      title: "Account Information",
      description:
        "Some text explaining why we need this company's industry, and why it's important to have this information.",
      formProps: {
        title: "What is your company's main industry?",
        saveDisabled: !industry,
      },
      form: (
        <IndustryAutocomplete
          industry={industry}
          setIndustry={editCompanyData("industry")}
        />
      ),
    },
    {
      field: "revenueYears",
      title: "Account Information",
      description:
        "More text explaining why we are asking for revenue numbers- tying Revenue to emissions (emissions/dollar spent)",
      formProps: {
        title: "What was your company's total revenue?",
        saveDisabled: !revenue || !numbersRegExpTest(revenue),
      },
      form: <DollarInput value={revenue} setValue={onRevenueEdit} />,
    },
    {
      field: "employeeCount",
      title: "Account Information",
      description:
        "Some text that talks about the employees and why it’s important to account for them ",
      formProps: {
        title: "How many employees did your company have?",
        saveDisabled: !employeeCount || !numbersRegExpTest(employeeCount),
      },
      form: (
        <EmployeeCount
          employeeCount={employeeCount}
          setEmployeeCount={editCompanyData("employeeCount")}
        />
      ),
    },
    {
      field: "isRemote",
      title: "Account Information",
      description: "Information on what remote means",
      formProps: {
        title: "Did your company have company operated facilities?",
        saveDisabled: isRemote === undefined,
      },
      form: (
        <YesNoCheckboxes
          value={isRemote !== undefined ? !isRemote : undefined}
          setValue={(value) => editCompanyData("isRemote")(!value)}
        />
      ),
    },
    ...addressStep,
    {
      field: "hasNoCompanyVehicles",
      title: "Account Information",
      description:
        "Text explaining why its important to capture information about company owned vehicles",
      formProps: {
        title: "Did your company own any vehicles?",
        saveDisabled: hasNoCompanyVehicles === undefined,
      },
      form: (
        <YesNoCheckboxes
          value={
            hasNoCompanyVehicles !== undefined
              ? !hasNoCompanyVehicles
              : undefined
          }
          setValue={(value) => editCompanyData("hasNoCompanyVehicles")(!value)}
        />
      ),
      section: "information",
    },
  ];

  const companyDataSteps = companyDataInputSteps.map(
    ({ form, formProps, field, ...otherProps }, idx) => ({
      ...otherProps,
      isComplete: companyData[field] !== undefined,
      form: (
        <FormDateTitleLayout
          {...formProps}
          field={field}
          value={companyData[field]}
          section="information"
          onNextStep={onNextStep}
          onPrevStep={idx && onPrevStep}
        >
          {form}
        </FormDateTitleLayout>
      ),
      section: "information",
    })
  );

  return [companyDataSteps, companyDataLoading, companyDataError];
};

const useFacilitiesInputsStep = ({
  facilities,
  setFacilities,
  isRemote,
  onNextStep,
  onPrevStep,
}) => {
  const [dbFacilities, dbFacilitiesLoading] = useVendorSurveyData("offices");

  const onVendorSubmit = useOnVendorSubmit();

  useEffect(() => {
    if (!dbFacilitiesLoading && dbFacilities.length) {
      setFacilities(dbFacilities);
    }
  }, [dbFacilities, dbFacilitiesLoading, setFacilities]);

  const facilitiesForm = !isRemote
    ? [
        {
          isComplete: !isObjectEmpty(facilities[0]),
          title: "Facility Information",
          description:
            "Information about what type of data is required for offices- location, sq feet, building type. Copy about activity vs spend based vs averages. If you know the total kWh or heating numbers, enter them here as well for the most accurate accounting- if not, we can use an average based on the facility information. You can also come back and add this later, just be sure to click ‘Save’",
          form: (
            <FacilitiesInputBlock
              onNextStep={onNextStep}
              onPrevStep={onPrevStep}
              facilities={facilities}
              setFacilities={setFacilities}
              onSave={() => onVendorSubmit("facilities", { facilities })}
            />
          ),
          section: "facilities",
        },
      ]
    : [];

  return [facilitiesForm, dbFacilitiesLoading];
};

const useTransactionsSteps = ({
  transactions,
  setTransactions,
  onNextStep,
  onPrevStep,
  hasNoCompanyVehicles,
  companyAddress,
  currentSurvey,
}) => {
  const onVendorSubmit = useOnVendorSubmit();

  const [dbTransactions, dbTransactionsLoading] =
    useVendorSurveyData("transactions");

  const [transactionsLoading, setTransactionsLoading] = useState(true);

  const [vehicleFuelData, setVehicleFuelData] = useState({});

  useEffect(() => {
    const setVehicleFuelTransaction = () => {
      if (!Array.isArray(dbTransactions)) {
        return;
      }

      const dbFuelTransaction = dbTransactions.find(
        ({ subcategory }) => subcategory === "fuel"
      );
      if (!dbFuelTransaction) {
        return;
      }
      const { billGallons, fuelCost } = dbFuelTransaction;
      return setVehicleFuelData({
        fuelVolume: billGallons,
        fuelDollars: fuelCost,
      });
    };

    const formatAndSetTransactions = () => {
      const scopeThreeCategoryGroupedTransactions = transactionsQuestions.map(
        ({ scopeThreeCategory }) => {
          const matchingTransactions = dbTransactions.filter(
            (transaction) =>
              transaction.scopeThreeCategory === scopeThreeCategory
          );

          return Object.fromEntries(
            matchingTransactions.map(({ naicsCode, value }) => [
              naicsCode,
              value,
            ])
          );
        }
      );

      return setTransactions(scopeThreeCategoryGroupedTransactions);
    };
    if (!dbTransactionsLoading) {
      setTransactionsLoading(false);
    }

    if (!dbTransactionsLoading && dbTransactions.length) {
      setVehicleFuelTransaction();
      formatAndSetTransactions();
    }
  }, [dbTransactions, dbTransactionsLoading, setTransactions]);

  const {
    fuelVolume,
    fuelVolumeUnit = "gallons",
    fuelDollars,
  } = vehicleFuelData;

  const editVehicleFuelData = (field) => (value) =>
    editObjectData(setVehicleFuelData, field, value);

  const onVehicleFuelSubmit = async () => {
    const findGallons = () => {
      if (fuelVolumeUnit === "liters") {
        return fuelVolume * 0.264172; //This is the conversion factor from liters to gallons
      }
      return fuelVolume;
    };

    const fetchTransactionTonsData = async () => {
      if (fuelVolume && fuelVolumeUnit) {
        return await fetchCarbonFromGallons({ gallons: findGallons() });
      }

      return await fetchFuelBillCarbon({
        billValue: fuelDollars,
        gasStation: companyAddress,
      });
    };

    const tonsCo2e = await fetchTransactionTonsData();

    return onVendorSubmit("transactions", {
      transactions: [
        {
          name: `${SURVEY_YEAR} Vehicle Fuel`,
          tonsCo2e,
          subcategory: "fuel",
          billGallons: findGallons(),
          fuelCost: fuelDollars,
        },
      ],
    });
  };

  const isVehicleFuelQuestionComplete = () => {
    if (!Array.isArray(dbTransactions)) {
      return false;
    }

    return !!dbTransactions?.find(({ subcategory }) => subcategory === "fuel");
  };

  const vehicleFuelQuestion = !hasNoCompanyVehicles
    ? [
        {
          isComplete: isVehicleFuelQuestionComplete(),
          title: "Vehicle Information",
          description:
            "Text explaining why its important to capture information about company owned vehicles",
          form: (
            <VehicleFuelQuestion
              onNextStep={onNextStep}
              onPrevStep={onPrevStep}
              editVehicleFuelData={editVehicleFuelData}
              onSave={onVehicleFuelSubmit}
              {...vehicleFuelData}
            />
          ),
          section: "facilities",
        },
      ]
    : [];

  const transactionsQuestionsSteps = transactionsQuestions.map((step, idx) => {
    const { title, description } = step;

    return {
      isComplete: !isObjectEmpty(transactions[idx]),
      title,
      description,
      form: (
        <TransactionsQuestionsLayout
          {...step}
          onNextStep={onNextStep}
          onPrevStep={onPrevStep}
          transaction={transactions[idx]}
          title={title}
          editTransactionRow={editRowData(idx, setTransactions)}
          isLastQuestion={idx + 1 === transactionsQuestions.length}
          currentSurvey={currentSurvey}
        />
      ),
      section: "transactions",
    };
  });

  return [
    [...vehicleFuelQuestion, ...transactionsQuestionsSteps],
    transactionsLoading,
  ];
};

const ThanksStep = () => {
  return (
    <Box
      style={{ height: "100%" }}
      display="fled"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="center" gutterBottom>
            Thanks for taking the survey
          </Typography>
          <Typography variant="h6" align="center">
            We've prepared a report summarizing your carbon emissions for{" "}
            {SURVEY_YEAR}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            href="/platform/company/dashboard"
          >
            See my report
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const useVendorSurveyFormSteps = () => {
  const [formStep, setFormStep] = useState(0);
  const [companyData, setCompanyData] = useState({});
  const [facilities, setFacilities] = useState([{}]);
  const [transactions, setTransactions] = useState(
    transactionsQuestions.map(() => ({}))
  );

  const { isRemote, hasNoCompanyVehicles, geography, vendorData } = companyData;
  const { surveys } = vendorData || {};
  const [currentSurvey] = surveys || [];
  const { dateStarted } = currentSurvey || {};

  const onNextStep = () => setFormStep((currentStep) => currentStep + 1);
  const onPrevStep = () => setFormStep((currentStep) => currentStep - 1);

  const companyAddress = geography?.address || facilities[0]?.address;

  const [companyDataSteps, companyDataLoading] = useCompanyDataSteps({
    companyData,
    setCompanyData,
    onNextStep,
    onPrevStep,
  });

  const [facilitiesForm, facilitiesDataLoading] = useFacilitiesInputsStep({
    facilities,
    setFacilities,
    isRemote,
    onNextStep,
    onPrevStep,
  });

  const [transactionsSteps, transactionsStepsLoading] = useTransactionsSteps({
    transactions,
    setTransactions,
    onNextStep,
    onPrevStep,
    hasNoCompanyVehicles,
    companyAddress,
    currentSurvey,
  });

  const surveyLoading =
    companyDataLoading || facilitiesDataLoading || transactionsStepsLoading;

  const surveySteps = useMemo(
    () => [
      {
        isComplete: !!dateStarted,
        title: "Account Information",
        description:
          "This slide will have some text about picking the year you would like to start your accounting- tip- this might be something that the customer already knows.",
        action:
          "Sign up for Aclymate to account for more than the previous calendar year. Sign up now.",
        form: (
          <YearConfirmation
            onNextStep={onNextStep}
            currentSurvey={currentSurvey}
          />
        ),
        section: "information",
      },
      ...companyDataSteps,
      ...facilitiesForm,
      ...transactionsSteps,
      {
        isComplete: false,
        title: "Thanks",
        description:
          "This will probably automatically redirect to the app once we figure out how we want to do that",
        form: <ThanksStep />,
      },
    ],
    [
      companyDataSteps,
      facilitiesForm,
      transactionsSteps,
      currentSurvey,
      dateStarted,
    ]
  );

  return { surveySteps, surveyLoading, formStep, onNextStep, setFormStep };
};

const VendorSurveyFormSteps = ({
  surveySteps,
  surveyLoading,
  formStep,
  error,
}) => {
  const currentStep = surveySteps[formStep] || {};

  return (
    <VendorSurveyLayout
      percentCompleted={formStep / surveySteps.length}
      surveyLoading={surveyLoading}
      error={
        error && {
          message:
            "You don't have access to this survey. Check the link or contact support if you believe you reached this in error.",
        }
      }
      {...currentStep}
    />
  );
};

const FormStepsStates = ({ isLoading, error, surveySteps, formStep }) => {
  if (isLoading) {
    return <VendorSurveyLayout surveyLoading />;
  }

  if (error) {
    return <VendorSurveyLayout error={error} />;
  }

  return (
    <VendorSurveyFormSteps
      surveySteps={surveySteps}
      surveyLoading={isLoading}
      formStep={formStep}
    />
  );
};

const InvitedUserSurvey = () => {
  const { companyId, invitationId } = useParams();
  const [authUser, userLoading] = useAuth();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invitationUser, setInvitationUser] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const [userFetched, setUserFetched] = useState(false);

  const { surveySteps, surveyLoading, formStep, onNextStep, setFormStep } =
    useVendorSurveyFormSteps();

  useEffect(() => {
    const fetchAndSetUserInvitationData = async () => {
      const authenticateUser = async () => {
        const { claims } = await authUser.getIdTokenResult({
          forceRefresh: true,
        });

        const { company } = claims;

        if (company === companyId) {
          const lastCompleteIdx = surveySteps.reduce(
            (acc, { isComplete }, idx) => (isComplete ? idx : acc),
            -1
          );

          const newFormStep = lastCompleteIdx > -1 ? lastCompleteIdx : 0;

          setIsLoading(false);
          return setFormStep(newFormStep);
        }

        setIsLoading(false);
        return setError({ message: "You don't have access to this survey." });
      };

      if (authUser && !isNewUser && !formStep) {
        return await authenticateUser();
      }

      const fetchAndSetInvitationUser = async () => {
        const { error, user } = await fetchOurApi({
          companyId,
          path: `/surveys/vendors/${companyId}/fetch-vendor-invitation`,
          method: "GET",
          callback: (res) => res,
        });

        setIsNewUser(true);
        setIsLoading(false);
        setUserFetched(true);

        if (error) {
          const errors = {
            "is-customer": {
              message:
                "It looks like you're already an Aclymate customer. Try logging in instead",
              href: "/",
              btnText: "Login",
            },
            "not-found": {
              message:
                "We were unable to find a survey for this company. Please check your link or contact support if you believe you are seeing this in error.",
            },
          };

          return setError(errors[error]);
        }

        if (user) {
          return setInvitationUser(user);
        }

        return;
      };

      if (isObjectEmpty(invitationUser) && !formStep && !userFetched) {
        return fetchAndSetInvitationUser();
      }

      return;
    };

    if (!userLoading && !error) {
      fetchAndSetUserInvitationData();
    }
  }, [
    invitationId,
    companyId,
    authUser,
    userLoading,
    isNewUser,
    onNextStep,
    invitationUser,
    formStep,
    surveySteps,
    setFormStep,
    userFetched,
    error,
  ]);

  const stepsWithAuth = [
    {
      section: "information",
      title: "Welcome to Aclymate's free accounting tool!",
      description:
        "This is the introduction slide. This side of the frame should have some helpful information about: What carbon accounting is, Why the person sent who sent you this invite sent it, Some high level info about what kind of information the type of information we are trying to gather and how higher fidelity information = higher quality data, Some semblance of how much time this going to take & that you can always save and exit to come back later. Need to note this is a free service unless you’d like to automate ",
      form: (
        <CreateAccount
          setIsLoading={setIsLoading}
          onNextStep={onNextStep}
          existingUser={invitationUser}
        />
      ),
    },
    ...surveySteps,
  ];

  return (
    <FormStepsStates
      isLoading={surveyLoading || isLoading}
      error={error}
      surveySteps={stepsWithAuth}
      formStep={formStep}
    />
  );
};

const ReturningUserSurvey = () => {
  const { companyId } = useParams();
  const [user, userLoading] = useAuth();

  const { surveySteps, surveyLoading, formStep } = useVendorSurveyFormSteps();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const authenticateUser = async () => {
      const { claims } = await user.getIdTokenResult({
        forceRefresh: true,
      });

      const { company } = claims;

      setIsLoading(false);
      if (company === companyId) {
        return setIsAuthenticated(true);
      }

      return setError({ message: "You don't have access to this survey." });
    };

    if (!userLoading && !error) {
      authenticateUser();
    }
  }, [user, userLoading, companyId, error]);

  if (isAuthenticated) {
    return (
      <FormStepsStates
        isLoading={isLoading || surveyLoading}
        error={error}
        surveySteps={surveySteps}
        formStep={formStep}
      />
    );
  }

  return (
    <VendorSurveyLayout
      section="information"
      title="Welcome to Aclymate's free accounting tool!"
      description="This is the introduction slide. This side of the frame should have some helpful information about: What carbon accounting is, Why the person sent who sent you this invite sent it, Some high level info about what kind of information the type of information we are trying to gather and how higher fidelity information = higher quality data, Some semblance of how much time this going to take & that you can always save and exit to come back later. Need to note this is a free service unless you’d like to automate "
      form={<Login />}
    />
  );
};

const VendorSurvey = () => {
  const { invitationId } = useParams();

  if (invitationId) {
    return <InvitedUserSurvey />;
  }
  return <ReturningUserSurvey />;
};
export default VendorSurvey;
