import dayjs from "dayjs";

import {
  faPlane,
  faBus,
  faCar,
  faTaxi,
  faShuttleVan,
  faTrain,
  faSubway,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";

import { mainTheme } from "@aclymatepackages/themes";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { Select } from "@aclymatepackages/atoms";
import { rentalCarCategories, states } from "@aclymatepackages/lists";
import { tonsToLbs, lbsToTons } from "@aclymatepackages/converters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { calcEventAttendeeTravelData } from "@aclymatepackages/calcs/events";
import { flightGcdFromCoordinates } from "@aclymatepackages/calcs/travel";
import { monthlyHomeOfficeUtilities } from "@aclymatepackages/calcs/recurring/defaultCalcs";

import ButtonCards from "../../components/atoms/buttons/ButtonCards";
import AirportAutocomplete from "../../components/inputs/autocomplete/AirportAutocomplete";

import {
  fetchOurApi,
  fetchDirectionsMileage,
  fetchZipCodeEGrid,
} from "../utils/apiCalls";
import { setAddressUpdateAirport } from "../utils/geography";
import { useCachedDisplayData } from "../firebase";

export const isAttendeeStateBreakdownPercentageError = (stateBreakdown) => {
  const attendeeStateBreakdownTotalPercentage = stateBreakdown.reduce(
    (sum, { percentage }) => sum + Number(percentage),
    0
  );
  return attendeeStateBreakdownTotalPercentage > 100;
};

export const editGroupAddress =
  (editGroup, { locationName }) =>
  async (place) => {
    if (place) {
      const { state } = place;
      await setAddressUpdateAirport(editGroup)(place);
      const [mileageDistanceMi, avgDailyDrivingMiles] = await Promise.all([
        fetchDirectionsMileage(locationName, place),
        fetchOurApi({
          path: "/calcs/cars/average-daily-driving",
          method: "POST",
          data: { state },
          callback: ({ avgDailyMiles }) => avgDailyMiles,
        }),
      ]);

      editGroup("avgDailyDrivingMiles")(avgDailyDrivingMiles);
      return editGroup("mileageDistance")(mileageDistanceMi);
    }

    editGroup("address")({});
    editGroup("defaultAirport")({});
    return editGroup("mileageDistance")(0);
  };

const buildTransitTypeQuestion = (value, setValue) => [
  {
    NextStepInput: ButtonCards,
    nextStepInputProps: {
      question: "What type of transit?",
      color: mainTheme.palette.secondary.main,
      selectedOption: value || "",
      onInputSelect: setValue,
      inputSelectPropName: "setOption",
      options: [
        { title: "Bus", value: "bus", fontAwesomeIcon: faBus },
        {
          title: "Light Rail",
          value: "lightRail",
          fontAwesomeIcon: faSubway,
        },
        {
          title: "Train",
          value: "train",
          fontAwesomeIcon: faTrain,
        },
      ],
    },
    value,
  },
];

const buildVehicleTypesQuestion = ({
  label,
  labelWidth,
  value = "",
  editValue,
}) => [
  {
    NextStepInput: Select,
    nextStepInputProps: {
      size: "small",
      label,
      labelWidth,
      value,
      onInputSelect: editValue,
      inputSelectPropName: "editValue",
      options: rentalCarCategories.map(({ name, type }) => ({
        label: name,
        value: type,
      })),
    },
    value,
  },
];

export const generateFlyingTravelQuestions = (
  eventData,
  travelGroup = {},
  editTravelGroup,
  direction = "to"
) => {
  const {
    publicTransitType,
    airportEventTransportationMethod,
    friendsCarpoolCarType,
    defaultAirport: attendeeClosestAirport = {},
    rentalVehicleType,
  } = travelGroup;
  const { airportName: attendeeClosestAirportName } =
    attendeeClosestAirport || {};

  const { defaultAirport: eventClosestAirport } = eventData || {};
  const { airportName: eventClosestAirportName } = eventClosestAirport || {};

  const isTravelingTo = direction === "to";

  const generateAirportYesNoQuestion = ({ question, value, setValue }) => ({
    NextStepInput: YesNoQuestion,
    nextStepInputProps: {
      question,
      value,
      onInputSelect: setValue,
      inputSelectPropName: "setValue",
      lightBackground: true,
    },
    value: value || value === false,
  });

  const generateAirportAutocompleteQuestion = ({
    airport = "",
    editAirport,
    label,
  }) => ({
    NextStepInput: AirportAutocomplete,
    nextStepInputProps: {
      label,
      airport,
      onInputSelect: editAirport,
      inputSelectPropName: "editValue",
      isInternational: true,
    },
    value: airport,
  });

  const generateToFromAirportQuestions = ({
    airportField,
    questionField,
    yesNoQuestion,
    airportQuestion,
  }) => {
    const questionValue = travelGroup[questionField];
    const airportValue = travelGroup[airportField];

    const selectAirportQuestion = !questionValue
      ? [
          generateAirportAutocompleteQuestion({
            airport: airportValue,
            editAirport: editTravelGroup(airportField),
            label: airportQuestion,
          }),
        ]
      : [];

    return [
      generateAirportYesNoQuestion({
        question: yesNoQuestion,
        value: questionValue,
        setValue: editTravelGroup(questionField),
      }),
      ...selectAirportQuestion,
    ];
  };

  const generateAirportToEventQuestions = () => {
    const lastFlyingQuestions = {
      publicTransit: buildTransitTypeQuestion(
        publicTransitType,
        editTravelGroup("publicTransitType")
      ),
      rental: buildVehicleTypesQuestion({
        label: "Select your rental vehicle type",
        labelWidth: 215,
        value: rentalVehicleType,
        editValue: editTravelGroup("rentalVehicleType"),
      }),
      carpool: buildVehicleTypesQuestion({
        label: "Select your friend's vehicle type",
        labelWidth: 230,
        value: friendsCarpoolCarType,
        editValue: editTravelGroup("friendsCarpoolCarType"),
      }),
      uber: [],
    };

    return [
      {
        NextStepInput: ButtonCards,
        nextStepInputProps: {
          question: `How are you getting ${
            isTravelingTo ? "from" : "to"
          } the airport ${direction} the event?`,
          color: mainTheme.palette.secondary.main,
          selectedOption: airportEventTransportationMethod || "",
          onInputSelect: editTravelGroup("airportEventTransportationMethod"),
          inputSelectPropName: "setOption",
          options: [
            {
              title: "Rental Car",
              value: "rental",
              fontAwesomeIcon: faCar,
            },
            {
              title: "Public Transit",
              value: "publicTransit",
              fontAwesomeIcon: faBus,
            },
            {
              title: "Uber/Lyft/Taxi",
              value: "uber",
              fontAwesomeIcon: faTaxi,
            },
            {
              title: "Riding with friends",
              value: "carpool",
              fontAwesomeIcon: faShuttleVan,
            },
          ],
        },
        value: airportEventTransportationMethod,
      },
      ...(lastFlyingQuestions[airportEventTransportationMethod] || []),
    ];
  };

  const firstAirportDirection = isTravelingTo ? "out of" : "to";
  const secondAirportDirection = isTravelingTo ? "into" : "from";

  return [
    ...generateToFromAirportQuestions({
      yesNoQuestion: `Are you flying ${firstAirportDirection} ${attendeeClosestAirportName}?`,
      airportQuestion: `What airport are you flying ${firstAirportDirection}?`,
      questionField: "isFlyingFromClosestAirport",
      airportField: "departureAirport",
    }),
    ...generateToFromAirportQuestions({
      yesNoQuestion: `Are you flying ${secondAirportDirection} ${eventClosestAirportName}?`,
      airportQuestion: `What airport are you flying ${secondAirportDirection}?`,
      questionField: "isFlyingToClosestAirport",
      airportField: "arrivalAirport",
    }),
    ...generateAirportToEventQuestions(),
  ];
};

export const buildTravelEmissionsSurveyRows = ({
  travelGroup,
  editTravelGroup,
  eventData,
  form,
  travelGroupEmailInputObj,
  hideGroupQuestions,
}) => {
  const {
    mainTransportationChoice,
    publicTransitType,
    carpoolCarType,
    personalVehicleType,
    travelGroupSize,
    isTravelGroup,
    mileageDistance,
  } = travelGroup;

  const buildTravelInfoForm = () => {
    const flyingQuestions = generateFlyingTravelQuestions(
      eventData,
      travelGroup,
      editTravelGroup
    );

    const personalVehicleQuestions = buildVehicleTypesQuestion({
      label: "Select your vehicle type",
      labelWidth: 200,
      value: personalVehicleType,
      editValue: editTravelGroup("personalVehicleType"),
    });

    const publicTransitQuestion = buildTransitTypeQuestion(
      publicTransitType,
      editTravelGroup("publicTransitType")
    );

    const carpoolQuestion = buildVehicleTypesQuestion({
      label: "Select your carpool vehicle type",
      labelWidth: 230,
      value: carpoolCarType,
      editValue: editTravelGroup("carpoolCarType"),
    });

    const pathForTravelQuestions = {
      flying: flyingQuestions,
      personalCar: personalVehicleQuestions,
      publicTransit: publicTransitQuestion,
      carpool: carpoolQuestion,
      walking: [],
    };

    const questionsDependingOnPath = mainTransportationChoice
      ? pathForTravelQuestions[mainTransportationChoice]
      : [];

    const isTravelGroupQuestion =
      travelGroupEmailInputObj &&
      !hideGroupQuestions &&
      mainTransportationChoice !== "carpool"
        ? [
            {
              NextStepInput: YesNoQuestion,
              nextStepInputProps: {
                question: "Are you traveling to the event with anyone else?",
                value: isTravelGroup,
                onInputSelect: editTravelGroup("isTravelGroup"),
                inputSelectPropName: "setValue",
                lightBackground: true,
              },
              value: isTravelGroup || isTravelGroup === false,
            },
          ]
        : [];

    const travelSizeQuestion = isTravelGroup
      ? [
          {
            label: "Besides yourself, how many adults are traveling with you?",
            value: travelGroupSize,
            editData: editTravelGroup("travelGroupSize"),
            error: !numbersRegExpTest(travelGroupSize),
          },
        ]
      : [];

    const travelGroupEmailsQuestion = isTravelGroup
      ? [travelGroupEmailInputObj]
      : [];

    const walkingCardOption =
      mileageDistance < 50
        ? [
            {
              title: "Walking/biking",
              value: "walking",
              fontAwesomeIcon: faWalking,
            },
          ]
        : [];

    const carpoolOption = !hideGroupQuestions
      ? [
          {
            title: "Carpool",
            value: "carpool",
            fontAwesomeIcon: faShuttleVan,
          },
        ]
      : [];

    return [
      {
        NextStepInput: ButtonCards,
        nextStepInputProps: {
          question: "How are you getting to the event?",
          color: mainTheme.palette.secondary.main,
          selectedOption: mainTransportationChoice || "",
          onInputSelect: (mainTransportationChoice) => {
            if (mainTransportationChoice === "carpool") {
              editTravelGroup("isTravelGroup")(true);
            }

            return editTravelGroup("mainTransportationChoice")(
              mainTransportationChoice
            );
          },
          inputSelectPropName: "setOption",
          options: [
            {
              title: "Flying",
              value: "flying",
              fontAwesomeIcon: faPlane,
            },
            {
              title: hideGroupQuestions ? "Driving" : "Driving My Own Car",
              value: "personalCar",
              fontAwesomeIcon: faCar,
            },
            {
              title: "Public Transit",
              value: "publicTransit",
              fontAwesomeIcon: faBus,
            },
            ...carpoolOption,
            ...walkingCardOption,
          ],
        },
        value: mainTransportationChoice,
      },
      ...questionsDependingOnPath,
      ...isTravelGroupQuestion,
      ...travelSizeQuestion,
      ...travelGroupEmailsQuestion,
    ];
  };

  return buildTravelInfoForm().map((row) => ({
    ...row,
    form,
  }));
};

export const displayEventOffsetCost = (footprint, totalThousandLbsCost) =>
  (tonsToLbs(footprint) / 1000) * totalThousandLbsCost;

const CAR_C02E_PER_MILE = 0.00033;
const DAILY_ENERGY_USAGE_TONS_PER_HOME_PER_DAY = 0.0054;
const REDUCTION_IN_ENERGY_USAGE = 25 / 100;
const DAILY_FUEL_CONSUMPTION_FOR_RV_PER_DAY = 2;

const calcNumberOfNights = ({ numberDaysAttended, endDate, startDate }) => {
  if (numberDaysAttended) {
    return Number(numberDaysAttended);
  }

  const daysDiff = Math.abs(dayjs(endDate).diff(startDate, "day"));
  if (daysDiff) {
    return daysDiff;
  }

  return 1;
};

const calcTonsCo2ForDifferentEndpoints = async (route, objData) =>
  await fetchOurApi({
    path: route,
    method: "POST",
    data: objData,
    callback: (res) => res,
  });

export const calcTonsCo2eForVehicleType = (vehicleType, mileage = 1) => {
  const { tonsCo2ePerMile, averageCombE } = rentalCarCategories.find(
    ({ type }) => type === vehicleType
  );

  if (tonsCo2ePerMile) {
    return mileage * tonsCo2ePerMile;
  }

  const methaneGWP = 25;
  const nitrogenGWP = 298;
  const defaultUsEgridData = { carbon: 852.3, methane: 0.071, nitrogen: 0.01 };
  const { carbon, methane, nitrogen } = defaultUsEgridData;
  const defaultEgridCarbonTonsPerMwh =
    lbsToTons(carbon) +
    lbsToTons(methane) * methaneGWP +
    lbsToTons(nitrogen) * nitrogenGWP;
  const mwhPerMile = averageCombE / 100 / 1000;

  return mwhPerMile * defaultEgridCarbonTonsPerMwh;
};

export const calcAvoidedEmissions = async ({ newAttendee, eventData }) => {
  const { startDate: eventStartDate, endDate: eventEndDate } = eventData;

  const {
    isThermostatSetOver7Degree,
    lodgingCategory,
    ticketType,
    dailyDrivingMileage,
    personalVehicleType,
    isNotStayingHome = true,
    numberDaysAttended,
  } = newAttendee;

  if (!isNotStayingHome) {
    return {
      type: "avoided",
      totalTonsCo2e: 0,
    };
  }

  const { ticketEndDate = "", ticketStartDate = "" } = ticketType || {};

  const numberOfNights = calcNumberOfNights({
    numberDaysAttended,
    endDate: ticketEndDate || eventEndDate,
    startDate: ticketStartDate || eventStartDate,
  });

  const addedDaysOnTopOfLodging =
    lodgingCategory === "tent" || lodgingCategory === "friends" ? 3 : 2;

  const totalAvoidEmissionsNights = numberOfNights + addedDaysOnTopOfLodging;

  const thermostatAvoidedEmissionsTons =
    !isThermostatSetOver7Degree ||
    (lodgingCategory !== "tent" && lodgingCategory !== "friends")
      ? 0
      : totalAvoidEmissionsNights *
        DAILY_ENERGY_USAGE_TONS_PER_HOME_PER_DAY *
        REDUCTION_IN_ENERGY_USAGE;

  const vehicleDrivingTons = personalVehicleType
    ? calcTonsCo2eForVehicleType(personalVehicleType, dailyDrivingMileage)
    : CAR_C02E_PER_MILE * dailyDrivingMileage;

  const avoidedMileageEmissionsTons =
    totalAvoidEmissionsNights * vehicleDrivingTons;

  return {
    type: "avoided",
    isThermostatSetOver7Degree,
    dailyDrivingMileage,
    totalTonsCo2e: thermostatAvoidedEmissionsTons + avoidedMileageEmissionsTons,
  };
};

export const calcFlyingEmissions = async (
  {
    isFlyingFromClosestAirport,
    isFlyingToClosestAirport,
    defaultAirport: attendeeDefaultAirport,
    departureAirport,
    arrivalAirport,
    rentalVehicleType,
    publicTransitType,
    friendsCarpoolCarType,
    airportEventTransportationMethod,
  },
  { defaultAirport: eventDefaultAirport, locationName: eventLocationName }
) => {
  const fromAirport = isFlyingFromClosestAirport
    ? attendeeDefaultAirport
    : departureAirport;

  const toAirport = isFlyingToClosestAirport
    ? eventDefaultAirport
    : arrivalAirport;

  const { flightCarbonTons } = await calcTonsCo2ForDifferentEndpoints(
    "/calcs/travel/fetch-gcd-and-carbon",
    { to: toAirport, from: fromAirport, passClass: "economy" }
  );
  const roundTripFlightCarbonTons = flightCarbonTons * 2;

  const { totalMileage } = await calcTonsCo2ForDifferentEndpoints(
    "/calcs/cars/directions-mileage",
    {
      to: eventLocationName,
      from: {
        coordinates: {
          lat: fromAirport.latitude,
          lng: fromAirport.longitude,
        },
      },
    }
  );

  const calcRental = async () => {
    const rentalTonsCo2e = calcTonsCo2eForVehicleType(
      rentalVehicleType,
      totalMileage
    );

    return {
      rentalVehicleType,
      fromAirportToEventVehicleType: "rental",
      fromAirportToEventTonsCo2e: rentalTonsCo2e,
    };
  };

  const calcPublicTransit = async () => {
    const { tonsCo2e: publicTransitCo2e } =
      await calcTonsCo2ForDifferentEndpoints(
        "/calcs/public-transit/public-transit-carbon-mileage",
        {
          publicTransitType: publicTransitType,
          mileage: totalMileage,
        }
      );

    return {
      fromAirportToEventVehicleType: "publicTransit",
      fromAirportToEventTonsCo2e: publicTransitCo2e,
    };
  };

  const calcUber = async () => {
    const { tonsCo2e: uberTonsCo2e } = await calcTonsCo2ForDifferentEndpoints(
      "/calcs/cars/total-mileage-carbon",
      { mileage: totalMileage }
    );

    return {
      fromAirportToEventVehicleType: "uber",
      fromAirportToEventTonsCo2e: uberTonsCo2e,
    };
  };

  const calcCarpool = async () => {
    const carpoolTonsCo2e = calcTonsCo2eForVehicleType(
      friendsCarpoolCarType,
      totalMileage
    );

    return {
      fromAirportToEventVehicleType: "carpool",
      fromAirportToEventTonsCo2e: carpoolTonsCo2e,
    };
  };

  const calcForTransportationMethod = {
    rental: calcRental,
    publicTransit: calcPublicTransit,
    uber: calcUber,
    carpool: calcCarpool,
  };

  const { fromAirportToEventVehicleType, fromAirportToEventTonsCo2e } =
    await calcForTransportationMethod[airportEventTransportationMethod]();

  return {
    type: "flying",
    fromAirport,
    toAirport,
    flightCarbonTons: roundTripFlightCarbonTons,
    fromAirportToEventVehicleType,
    fromAirportToEventTonsCo2e,
    totalTonsCo2e: flightCarbonTons + fromAirportToEventTonsCo2e,
  };
};

export const calcTravelEmissions = async ({ newAttendee, eventData }) => {
  const {
    mainTransportationChoice,
    personalVehicleType,
    mileageDistance,
    publicTransitType,
    carpoolCarType,
    travelGroupSize = 0,
    isNotStayingHome = true,
    ticketType = {},
    numberDaysAttended = 0,
  } = newAttendee;
  const { startDate: eventStartDate, endDate: eventEndDate } = eventData;

  const { ticketStartDate, ticketEndDate } = ticketType || {};

  if (mainTransportationChoice === "flying") {
    return await calcFlyingEmissions(newAttendee, eventData);
  }

  const adjustGroupSizeVehicleEmisions = (vehicleTons) =>
    vehicleTons / (Number(travelGroupSize) + 1);

  const calcRoundTripTravelMileage = () => {
    if (isNotStayingHome) {
      return mileageDistance * 2;
    }

    const numberOfNights = calcNumberOfNights({
      numberDaysAttended,
      startDate: ticketStartDate || eventStartDate,
      endDate: ticketEndDate || eventEndDate,
    });
    const roundTripDays = numberOfNights + 1;

    return roundTripDays * mileageDistance * 2;
  };

  const roundTripTravelMileage = calcRoundTripTravelMileage();

  if (mainTransportationChoice === "personalCar") {
    const roundTripTotalVehicleTons = calcTonsCo2eForVehicleType(
      personalVehicleType,
      roundTripTravelMileage
    );

    return {
      type: "personalCar",
      vehicleType: personalVehicleType,
      totalTonsCo2e: adjustGroupSizeVehicleEmisions(roundTripTotalVehicleTons),
      mileageDistance,
    };
  }

  if (mainTransportationChoice === "publicTransit") {
    const { tonsCo2e } = await calcTonsCo2ForDifferentEndpoints(
      "/calcs/public-transit/public-transit-carbon-mileage",
      { publicTransitType, mileage: roundTripTravelMileage }
    );

    return {
      type: "publicTransit",
      publicTransitType,
      mileageDistance,
      totalTonsCo2e: tonsCo2e,
    };
  }

  if (mainTransportationChoice === "carpool") {
    const roundCarpoolTonsCo2e = calcTonsCo2eForVehicleType(
      carpoolCarType,
      roundTripTravelMileage
    );

    return {
      type: "carpool",
      totalTonsCo2e: adjustGroupSizeVehicleEmisions(roundCarpoolTonsCo2e),
      carpoolCarType,
    };
  }

  return { type: "walking", totalTonsCo2e: 0 };
};

export const calcLodgingEmissions = async ({ newAttendee, eventData }) => {
  const { startDate: eventStartDate, endDate: eventEndDate } = eventData;

  const {
    lodgingCategory,
    lodgingAddress,
    ticketType,
    lodgingGroupSize,
    ticketLodgingCategory,
    isNotStayingHome,
    numberDaysAttended,
  } = newAttendee;

  if (isNotStayingHome === false) {
    return { type: "home", totalTonsCo2e: 0 };
  }

  const { ticketEndDate = "", ticketStartDate = "" } = ticketType || {};

  const numberOfNights = calcNumberOfNights({
    startDate: ticketStartDate || eventStartDate,
    endDate: ticketEndDate || eventEndDate,
    numberDaysAttended,
  });

  const findLodgingEmissionsByType = async (address, type) => {
    if (type === "tent" || type === "friends") {
      return { type, tonsCo2e: 0 };
    }

    if (type === "hotel") {
      const { starRating = 3 } = await calcTonsCo2ForDifferentEndpoints(
        "/calcs/travel/fetch-hotel-star-rating",
        { ...address }
      );

      const { tonsCo2e } = await calcTonsCo2ForDifferentEndpoints(
        "/calcs/travel/fetch-hotels-carbon",
        { ...address, numberOfNights, starRating }
      );

      return {
        type,
        address,
        starRating,
        totalTonsCo2e: tonsCo2e,
      };
    }

    if (type === "property") {
      const squareFootage = lodgingGroupSize * 400;

      const eGrid = await fetchZipCodeEGrid(address);

      const { monthlyGasCarbonTons, monthlyElectricCarbonTons } =
        monthlyHomeOfficeUtilities({
          ...address,
          eGrid,
          homeSqFootage: squareFootage,
          homeOfficeSqFootage: squareFootage,
        });

      const totalTonsCo2e =
        (((monthlyGasCarbonTons + monthlyElectricCarbonTons) / 30) *
          numberOfNights) /
        lodgingGroupSize;

      return {
        type,
        totalTonsCo2e,
      };
    }

    if (type === "rv") {
      const { tonsCo2 } = await calcTonsCo2ForDifferentEndpoints(
        "/calcs/cars/carbon-from-gallons",
        {
          gallons: DAILY_FUEL_CONSUMPTION_FOR_RV_PER_DAY,
          fuelType: "diesel",
        }
      );
      return {
        type,
        totalTonsCo2e: tonsCo2,
      };
    }

    return {};
  };

  return await findLodgingEmissionsByType(
    lodgingAddress,
    ticketLodgingCategory || lodgingCategory
  );
};

export const calculateEmployeeEmissions = (employeeData) => {
  const sumTonsCo2e = (employees) =>
    employees.reduce((sum, { tonsCo2e }) => sum + tonsCo2e, 0);

  const employeeEmissions = !!employeeData.length
    ? sumTonsCo2e(employeeData)
    : 0;

  return employeeEmissions;
};

export const useDisplayEvents = () => {
  const [transactions, transactionsLoading] =
    useCachedDisplayData("transactions");
  const [events, eventsLoading] = useCachedDisplayData("events");

  const dataLoading = transactionsLoading || eventsLoading;

  const findEventStatus = ({ startDate, endDate, isClosed }) => {
    if (isClosed) {
      return "closed";
    }
    if (dayjs().isAfter(dayjs(endDate))) {
      return "past";
    }
    if (dayjs().isAfter(dayjs(startDate))) {
      return "inProgress";
    }
    return "upcoming";
  };

  const calculateEmissionsFromAttendeeStateBreakdown = (eventData) => {
    const {
      defaultAirport,
      address,
      attendeesStateBreakdown = [],
      attendeeCount,
    } = eventData;
    const { coordinates } = address;

    const eventFromAirportDistance = flightGcdFromCoordinates(
      coordinates,
      defaultAirport
    );

    const totalTravelTonsPerState = attendeesStateBreakdown.map(
      (attendeeState) => {
        const { state, percentage } = attendeeState;
        if (!state || !percentage) {
          return { ...attendeeState, tonsCo2e: 0 };
        }
        const { centroid = {}, country } =
          states.find((stateObj) => stateObj.name === state.toLowerCase()) ||
          {};

        const numberOfAttendees = attendeeCount * (percentage / 100);
        const { totalTravelTons } = calcEventAttendeeTravelData({
          attendeeData: { coordinates: centroid, country, state },
          eventData,
          nearestAirport: centroid,
          fromAirportDistanceMi: eventFromAirportDistance,
        });

        return {
          ...attendeeState,
          numberOfAttendees,
          tonsCo2e: totalTravelTons * numberOfAttendees,
        };
      }
    );

    const knownStatesTonsSum = sumTonsCo2e(totalTravelTonsPerState);
    const knownStatesPercentage = attendeesStateBreakdown.reduce(
      (sum, { percentage }) => sum + percentage,
      0
    );

    if (knownStatesPercentage === 100) {
      return knownStatesTonsSum;
    }

    const remainingAttendees =
      attendeeCount * (100 / (100 - knownStatesPercentage));

    const remainingStates = states.filter(
      ({ name }) => !attendeesStateBreakdown.find(({ state }) => state === name)
    );
    const remainingStatesTotalPopulation = remainingStates.reduce(
      (sum, { population }) => population + sum,
      0
    );

    const remainingStatesEmissionsTons = remainingStates.map((state) => {
      const { population, centroid = {}, name, country } = state;
      const populationTotalPercentage =
        population / remainingStatesTotalPopulation;
      const numberOfAttendees = populationTotalPercentage * remainingAttendees;

      const { totalTravelTons } = calcEventAttendeeTravelData({
        attendeeData: { coordinates: centroid, country, state: name },
        eventData,
        nearestAirport: centroid,
        fromAirportDistanceMi: eventFromAirportDistance,
      });

      return { ...state, tonsCo2e: totalTravelTons, numberOfAttendees };
    });

    const remainingStatesTotalTons = sumTonsCo2e(remainingStatesEmissionsTons);

    return remainingStatesTotalTons + knownStatesTonsSum;
  };

  const formattedEvents = events.map((event) => {
    const status = findEventStatus(event);
    const { address, venueElectricTons, venueGasTons, name } = event || {};
    const { description } = address || {};

    const eventTransactions = transactions.filter(
      ({ event }) => name === event?.name
    );

    const totalTransactionsTons = sumTonsCo2e(eventTransactions);

    const transactionSubcategories = [
      ...new Set(eventTransactions.map(({ subcategory }) => subcategory)),
    ];

    const attendeeEmissionsTons =
      calculateEmissionsFromAttendeeStateBreakdown(event);

    const transactionSubcategoriesTons = Object.fromEntries(
      transactionSubcategories.map((subcategory) => {
        const subcategoryTransactions = eventTransactions.filter(
          ({ subcategory: transactionSubcategory }) =>
            transactionSubcategory === subcategory
        );
        const subcategoryTonsCo2e = sumTonsCo2e(subcategoryTransactions);

        return [subcategory, subcategoryTonsCo2e];
      })
    );

    return {
      ...event,
      status,
      location: description,
      attendeeEmissionsTons,
      totalTransactionsTons,
      transactionSubcategoriesTons,
      tonsCo2e:
        venueElectricTons +
        venueGasTons +
        attendeeEmissionsTons +
        totalTransactionsTons,
    };
  });

  return [formattedEvents, dataLoading];
};
