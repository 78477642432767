import React, { useState, useContext } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { Avatar, Grid, Skeleton, useTheme } from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import {
  faUserFriends,
  faMoneyBill,
  faChartArea,
  faChartBar,
  faChartLineDown,
  faChartPie,
} from "@fortawesome/pro-solid-svg-icons";

import { ToggleButtons } from "@aclymatepackages/atoms";
import {
  formatDate,
  ucFirstLetters,
  formatDecimal,
} from "@aclymatepackages/formatters";
import { arrayToString } from "@aclymatepackages/converters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import { EmissionsPieChart } from "@aclymatepackages/modules";
import findSubcategory, { findScope } from "@aclymatepackages/subcategories";

import PrimaryViewTables from "./PrimaryViewTables";

import ErrorBoundary from "../../../../atoms/ErrorBoundary";
import ButtonMenu from "../../../../atoms/buttons/ButtonMenu";

import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import ViewGraphCardLayout from "../../../../layouts/ViewGraphCard";

import EmissionsChart from "../../../../modules/charts/EmissionsChart";
import FilterChips from "../../../../modules/filter/FilterChips";
import DateIntervalToggles from "../../../../modules/DateIntervalToggles";
import DateRangeFilter from "../../../../modules/filter/DateRangeFilter";

import ManualTransactionsInput from "../../../../inputs/emissions/ManualTransactionsInput";
import MultipleTransactionsUploader from "../../../../inputs/csv-uploaders/MultipleTransactionsUploader";

import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import { useAccountData } from "../../../../../helpers/firebase";
import {
  usePrimaryViewAlerts,
  extractScopeThreeEmissions,
} from "../../../../../helpers/components/primaryView";
import { filterByDateRange } from "../../../../../helpers/utils/dates";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const PrimaryViewCharts = ({
  pieChartEmissions,
  barChartEmissions,
  emissionsLoading,
  viewMode,
  graphPeriod,
}) => {
  const [{ startDate }] = useAccountData();
  const [showTrendline, setShowTrendline] = useState(false);

  const formattedBarChartEmissions =
    extractScopeThreeEmissions(barChartEmissions);

  const sharedChartProps = {
    startDate,
    viewMode,
    showTrendline,
    noLine: false,
    graphPeriod,
  };

  const historyGraphs = [
    {
      title: "Emissions History",
      type: "bar",
      icon: faChartBar,
      Graph: EmissionsChart,
      graphProps: {
        startDate,
        type: "bar",
        ...sharedChartProps,
      },
    },
    {
      title: "Emissions History",
      type: "scatter",
      icon: faChartArea,
      Graph: EmissionsChart,
      graphProps: {
        startDate,
        type: "scatter",
        ...sharedChartProps,
      },
    },
  ];

  const pieChart = [
    {
      title: "Current Emissions",
      Graph: EmissionsPieChart,
      graphProps: { viewMode },
    },
  ];

  return (
    <Grid container item spacing={3} alignItems="stretch">
      {emissionsLoading ? (
        <>
          <Grid item xs={8}>
            <Skeleton variant="rectangular" height={250} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="rectangular" height={250} />
          </Grid>
        </>
      ) : (
        <>
          <ViewGraphCardLayout
            gridProps={{ xs: 8 }}
            toggles={[
              {
                label: "Show Trendline",
                value: showTrendline,
                setValue: setShowTrendline,
              },
            ]}
            graphs={historyGraphs}
            color="secondary"
            dataArray={formattedBarChartEmissions}
            icon={<FontAwesomeIcon icon={faChartLineDown} />}
            analyticsCta="to see how your emissions have changed monthly, quarterly, or yearly"
          />
          <ViewGraphCardLayout
            gridProps={{ xs: 4 }}
            color="secondary"
            graphs={pieChart}
            dataArray={pieChartEmissions}
            icon={<FontAwesomeIcon icon={faChartPie} />}
            style={{ height: "100%" }}
            analyticsCta="to see the breakdown of your emissions by category"
          />
        </>
      )}
    </Grid>
  );
};

const AddTransactionsMenu = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const inputDialogs = {
    transactionsCsv: (
      <MultipleTransactionsUploader
        setOpen={setDialogOpen}
        open={!!dialogOpen}
      />
    ),
    manualTransactions: (
      <ManualTransactionsInput setDialogOpen={setDialogOpen} />
    ),
  };

  return (
    <>
      {dialogOpen && inputDialogs[dialogOpen]}
      <ButtonMenu
        color="primary"
        menuOptions={[
          {
            label: "Add a single emission",
            onClick: () => setDialogOpen("manualTransactions"),
          },
          {
            label: "Upload Multiple Emissions",
            onClick: () => setDialogOpen("transactionsCsv"),
          },
        ]}
      />
    </>
  );
};

const PrimaryViewBlock = ({ allEmissions, viewLoading }) => {
  const { palette } = useTheme();
  const [{ startDate: companyStartDate }] = useAccountData();
  const { displayUnitLabel, convertCarbonUnits } = useContext(
    PlatformLayoutContext
  );
  const dashboardViewTogglesFlag = useFeatureIsOn("dashboard-view-toggles");

  const { transactionAlert } = usePrimaryViewAlerts({
    allEmissions,
  });

  const [viewMode, setViewMode] = useState("subcategories");
  const [selectedChips, setSelectedChips] = useState(["all"]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [graphPeriod, setGraphPeriod] = useState("month");
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  const findEmissionsWithTags = (emissionTag, selectedTags) => {
    if (!selectedTags.length) {
      return true;
    }

    const selectedTagsNames = selectedTags.map(({ name }) => name);

    if (Array.isArray(emissionTag)) {
      return !!emissionTag.filter((tagObj) =>
        selectedTagsNames.includes(ucFirstLetters(tagObj.name))
      ).length;
    }

    return selectedTagsNames.includes(ucFirstLetters(emissionTag?.name));
  };

  const applyFilters = () => {
    const filterByChips = (emission) => {
      const filterBySubcategory = ({ subcategory }) => {
        if (selectedChips.includes("all")) {
          return true;
        }

        return selectedChips.includes(subcategory);
      };

      const filterByScope = ({ scope }) => {
        if (selectedChips.includes("all")) {
          return true;
        }
        return selectedChips.includes(scope);
      };
      if (viewMode === "subcategories") {
        return filterBySubcategory(emission);
      }
      return filterByScope(emission);
    };

    const filterByTransaction = (emission) => {
      if (!selectedTransactions.length) {
        return true;
      }

      return selectedTransactions
        .map(({ name }) => name)
        .includes(emission.name);
    };

    const filterByEmployees = ({ employees }) =>
      findEmissionsWithTags(employees, selectedEmployees);

    const filterByOffice = ({ office }) =>
      findEmissionsWithTags(office, selectedOffices);

    const filterByKnownVendor = ({ knownVendor }) =>
      findEmissionsWithTags(knownVendor, selectedVendors);

    return allEmissions.filter((emission) => {
      const { tonsCo2e } = emission;
      return (
        tonsCo2e &&
        filterByTransaction(emission) &&
        filterByEmployees(emission) &&
        filterByOffice(emission) &&
        filterByKnownVendor(emission) &&
        filterByChips(emission) &&
        filterByDateRange({ filterEndDate, filterStartDate, ...emission })
      );
    });
  };

  const filteredEmissions = applyFilters();

  const resetFilters = () => {
    setSelectedTransactions([]);
    setSelectedEmployees([]);
    setSelectedOffices([]);
    return setSelectedVendors([]);
  };

  const populateFilterAutocompletes = (type) => {
    if (viewLoading) {
      return [];
    }

    const rowsByFilterType = allEmissions.filter((row) => row[type]);

    const namesByFilterType = rowsByFilterType.map((row) => {
      const { employees } = row;

      if (employees?.length && type === "employees") {
        return employees.map(({ name }) => name);
      }

      return row[type].name;
    });

    const formattedNames =
      type === "employees"
        ? namesByFilterType.reduce((acc, names = []) => [...acc, ...names], [])
        : namesByFilterType;

    const namesNoDups = formattedNames.length
      ? [...new Set(formattedNames)]
      : [];

    return namesNoDups
      .filter((name) => name)
      .map((name) => ({ name: ucFirstLetters(String(name)) }));
  };

  const filterRows = [
    {
      icon: faBuilding,
      title: "Offices",
      type: "office",
      autocomplete: {
        availableOptions: populateFilterAutocompletes("office"),
        value: selectedOffices,
        setValue: setSelectedOffices,
        label: "Offices",
      },
    },
    {
      icon: faUserFriends,
      title: "Employees",
      type: "employee",
      autocomplete: {
        availableOptions: populateFilterAutocompletes("employees"),
        value: selectedEmployees,
        setValue: setSelectedEmployees,
        label: "Employees",
      },
    },
    {
      icon: faMoneyBill,
      title: "Vendors",
      type: "vendor",
      autocomplete: {
        availableOptions: populateFilterAutocompletes("knownVendor"),
        value: selectedVendors,
        setValue: setSelectedVendors,
        label: "Vendors",
      },
    },
  ];

  const buildChipsArray = () => {
    if (viewMode === "subcategories") {
      const uniqueEmissionsSubcategories = [
        ...new Set(allEmissions.map(({ subcategory }) => subcategory)),
      ];

      return uniqueEmissionsSubcategories.map((subcategory) => {
        const subcategoryData = findSubcategory(subcategory);
        return {
          ...subcategoryData,
          id: subcategory,
        };
      });
    }

    const uniqueEmissionsScopes = [
      ...new Set(allEmissions.map(({ scope }) => scope)),
    ];

    return uniqueEmissionsScopes.map((scope) => {
      const { color, name } = findScope(scope);

      return {
        name: `Scope ${name}`,
        id: scope,
        color: palette[color].main,
      };
    });
  };

  const buildSubtitle = () => {
    const emissionsSumTons = sumTonsCo2e(filteredEmissions);
    if (!emissionsSumTons) {
      return "There are no emissions from your company that match this filtering criteria.";
    }

    const buildDateString = () => {
      const formatDateString = (date) => date.format("MMMM YYYY");

      if (filterStartDate && filterEndDate) {
        return `from ${formatDateString(filterStartDate)} to ${formatDateString(
          filterEndDate
        )}`;
      }

      if (filterEndDate) {
        return `before ${formatDateString(filterEndDate)}`;
      }

      if (filterStartDate) {
        return `since ${formatDateString(filterStartDate)}`;
      }

      return `since your start date (${formatDate(companyStartDate)})`;
    };

    const buildChipsString = () => {
      if (selectedChips.includes("all")) {
        return "";
      }

      const mapFunction =
        viewMode === "subcategories"
          ? (subcategory) => findSubcategory(subcategory).name
          : (scope) => `scope ${scope}`;

      const selectedChipNames = selectedChips.map(mapFunction);

      return `from ${arrayToString(selectedChipNames)}`;
    };

    const buildTagsString = () => {
      const selectedEmployeeNames = selectedEmployees.map(({ name }) => name);
      const selectedOfficesNames = selectedOffices.map(({ name }) => name);
      const selectedVehicleNames = selectedVendors.map(({ name }) => name);

      const allTagsArray = [
        ...selectedEmployeeNames,
        ...selectedOfficesNames,
        ...selectedVehicleNames,
      ];
      if (!allTagsArray.length) {
        return "Your company has";
      }
      const actionVerb = allTagsArray.length === 1 ? "has" : "have";
      return `${arrayToString(allTagsArray)} ${actionVerb}`;
    };

    return `${buildTagsString()} emitted ${formatDecimal(
      convertCarbonUnits(emissionsSumTons)
    )} ${displayUnitLabel} of CO2 ${buildChipsString()}  ${buildDateString()}.`;
  };

  const isFiltered =
    selectedTransactions.length ||
    selectedEmployees.length ||
    selectedOffices.length ||
    selectedVendors.length;

  return (
    <DashboardViewLayout
      alerts={transactionAlert}
      type="primary"
      viewLoading={viewLoading}
      title="Carbon Accounting Summary"
      subtitle={buildSubtitle()}
      isFiltered={isFiltered}
      filterPopperProps={{
        filterRows,
        resetFilters,
        title: "Filter Emissions",
      }}
      primaryAction={<AddTransactionsMenu />}
      secondaryAction={
        dashboardViewTogglesFlag && (
          <ToggleButtons
            value={viewMode}
            onChange={(viewMode) => {
              setSelectedChips(["all"]);
              return setViewMode(viewMode);
            }}
            buttons={[
              {
                value: "subcategories",
                name: "Types",
                id: "subcategories-option",
              },
              { value: "scopes", name: "Scopes", id: "scopes-option" },
            ]}
          />
        )
      }
      premiumActions={[
        <DateRangeFilter
          filterStartDate={filterStartDate}
          setFilterStartDate={setFilterStartDate}
          filterEndDate={filterEndDate}
          setFilterEndDate={setFilterEndDate}
        />,
        <DateIntervalToggles
          graphPeriod={graphPeriod}
          setGraphPeriod={setGraphPeriod}
        />,
      ]}
      chips={
        <FilterChips
          chipsArray={buildChipsArray()}
          selectedChips={selectedChips}
          setSelectedChips={setSelectedChips}
        />
      }
      avatar={
        <Avatar style={{ backgroundColor: palette.secondary.main }}>
          <PlaylistAddCheckIcon />
        </Avatar>
      }
      graph={
        <PrimaryViewCharts
          pieChartEmissions={allEmissions}
          barChartEmissions={filteredEmissions}
          emissionsLoading={viewLoading}
          viewMode={viewMode}
          graphPeriod={graphPeriod}
        />
      }
      table={
        <PrimaryViewTables
          selectedChips={selectedChips}
          allEmissions={allEmissions}
          emissionsLoading={viewLoading}
          summaryTableEmissions={filteredEmissions}
          viewMode={viewMode}
          graphPeriod={graphPeriod}
        />
      }
    />
  );
};

const PrimaryView = (props) => {
  return (
    <ErrorBoundary>
      <PrimaryViewBlock {...props} />
    </ErrorBoundary>
  );
};
export default PrimaryView;
