import { createContext, useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { splitScopeEmissions } from "@aclymatepackages/chart-helpers";
import {
  buildMonthlyDefaultEmissions,
  buildEmissionsMonthsArray,
  findRecurringEmissionsStateVariables,
  calcSeparateUtilitiesOfficesDefaultEmissions,
  deduplicateUtilitiesTransactionsPerOffice,
  buildAllEmissionsArray,
} from "@aclymatepackages/calcs/recurring";

import { useEmployeeCount } from "../hooks/companyData";
import { emissionStatuses } from "../components/primaryView";
import { PlatformLayoutContext } from "./platformLayout";
import { SandboxDataContext } from "./sandboxData";
import { AdminDataContext } from "./adminData";

import { getAccountCollectionAndId } from "../otherHelpers";
import { useCachedDisplayData, useAccountData } from "../firebase";

import { baseFetchHost } from "../utils/apiCalls";
import useAccountingData from "../hooks/accountingData";
import { useDisplayEvents } from "../components/events";

dayjs.extend(isSameOrAfter);

const EmissionsContext = createContext();

const buildEmissionStatus = ({ id, emissionDate, status }) => {
  if (id && status !== "confirmed") {
    return "unconfirmed";
  }

  if (dayjs(emissionDate).isAfter(dayjs())) {
    return "cleared";
  }

  return "confirmed";
};

const splitAndFormatEmissions = ({
  transactions,
  separateUtilitiesOfficesDefaultEmissions,
  monthlyCommuteEmissionsTons,
  monthlyUtilitiesEmissionsTons,
  mostRecentAccountingDate,
  events,
}) => {
  const eventsWithStatuses = events.length
    ? events.map((event) => {
        const { endDate: date } = event;

        const currentStatus = buildEmissionStatus({ emissionDate: date });
        const { severity } = emissionStatuses[currentStatus] || {};

        return {
          ...event,
          currentStatus,
          severity,
        };
      })
    : [];

  const allEmissions = buildAllEmissionsArray({
    transactions,
    separateUtilitiesOfficesDefaultEmissions,
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    mostRecentAccountingDate,
    events: eventsWithStatuses,
  });

  const emissionsWithStatusObjs = allEmissions.map((emission) => {
    const { currentStatus } = emission;
    const { severity } = emissionStatuses[currentStatus] || {};

    return { ...emission, currentStatus, severity };
  });

  const splitEmissions = splitScopeEmissions(emissionsWithStatusObjs);

  const taggedOfficeTransactions = [
    ...transactions,
    ...separateUtilitiesOfficesDefaultEmissions,
  ].filter(({ taggedOfficeId }) => taggedOfficeId);

  const taggedEmployeesTransactions = transactions.filter(
    ({ taggedEmployeesIds }) => taggedEmployeesIds
  );

  return {
    allEmissions: splitEmissions,
    taggedOfficeTransactions,
    taggedEmployeesTransactions,
  };
};

export const EmissionsContextProvider = ({ children }) => {
  const { viewMode = "company" } = useContext(PlatformLayoutContext) || {};
  const { sandboxData } = useContext(SandboxDataContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};
  const { companyData: sandboxCompanyData } = sandboxData || {};
  const { companyData: adminCompanyData } = adminData || {};
  const { startDate: sandboxStartDate } = sandboxCompanyData || {};
  const { startDate: adminStartDate } = adminCompanyData || {};

  const { id: companyId } = getAccountCollectionAndId();
  const [accountData, accountDataLoading] = useAccountData();
  const [{ mostRecentAccountingDate }, accountingDataLoading] =
    useAccountingData();
  const [employeeCount, employeeCountLoading] = useEmployeeCount();
  const {
    startDate: companyStartDate,
    isRemote,
    geography = {},
    industry,
  } = accountData;
  const { eGrid } = geography;

  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [dbTransactions, transactionsLoading] =
    useCachedDisplayData("transactions");
  const [events, eventsLoading] = useDisplayEvents();
  const nonAdminEvents = events.filter(({ companies }) => {
    if (!companies) {
      return false;
    }
    const { type } = companies.find(({ id }) => id === companyId) || {};
    return type === "owner";
  });

  const recurringEmissionsLoading =
    employeesLoading ||
    officesLoading ||
    accountDataLoading ||
    transactionsLoading ||
    eventsLoading ||
    accountingDataLoading ||
    employeeCountLoading;

  const emissionStartDates = {
    admin: adminStartDate,
    sandbox: sandboxStartDate,
    company: companyStartDate,
  };
  const emissionsStartDate = emissionStartDates[viewMode];

  const emissionsMonths = buildEmissionsMonthsArray(emissionsStartDate);
  const monthlyDefaultEmissions = buildMonthlyDefaultEmissions({
    emissionsMonthsArray: emissionsMonths,
    employees,
    employeeCount,
    isRemote,
    industry,
    companyId,
    geography,
    eGrid,
  });

  const transactionsSinceStartDate = dbTransactions.filter((transaction) =>
    dayjs(transaction?.date).isSameOrAfter(emissionsStartDate)
  );

  const transactions = deduplicateUtilitiesTransactionsPerOffice(
    emissionsMonths,
    transactionsSinceStartDate
  );

  const separateUtilitiesOfficesDefaultEmissions =
    calcSeparateUtilitiesOfficesDefaultEmissions({
      companyId,
      startDate: emissionsStartDate,
      offices,
      transactions,
      baseFetchHost,
    });

  const buildOfficesCalcsArray = () => {
    if (offices.length) {
      return offices;
    }

    return [
      {
        type: "companyOffice",
        utilities: {
          eGrid,
          utilitiesInLease: true,
        },
      },
    ];
  };

  const {
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    totalRecurringEmissionsTons,
    averageMonthlyRecurringEmissionsTons,
  } = findRecurringEmissionsStateVariables({
    employees,
    offices: buildOfficesCalcsArray(),
    companyStartDate: emissionsStartDate,
    emissionsMonths: monthlyDefaultEmissions,
    companyId,
  });

  const {
    allEmissions,
    taggedOfficeTransactions,
    taggedEmployeesTransactions,
  } = splitAndFormatEmissions({
    mostRecentAccountingDate,
    transactions,
    separateUtilitiesOfficesDefaultEmissions,
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    events: nonAdminEvents,
  });

  return (
    <EmissionsContext.Provider
      value={{
        emissionsMonths: monthlyDefaultEmissions,
        monthlyCommuteEmissionsTons,
        monthlyUtilitiesEmissionsTons,
        totalRecurringEmissionsTons,
        averageMonthlyRecurringEmissionsTons,
        recurringEmissionsLoading,
        separateUtilitiesOfficesDefaultEmissions,
        transactions,
        allEmissions,
        taggedOfficeTransactions,
        taggedEmployeesTransactions,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};

const useEmissionsContext = () => useContext(EmissionsContext);
export default useEmissionsContext;
